import React from "react";
import { Parallax } from "react-scroll-parallax";
import TagManager from "react-gtm-module";
import "./styles.scss";
import PantryDescription from "../PantryDescription";

class Pantry extends React.Component {
	componentDidMount() {
		const data = {
			event: "PageView",
			pageDetails: {
				page: "pantry",
				pagePath: "/pantry",
			},
		};
		TagManager.dataLayer(data);
	}

	render() {
		return (
			<div className="pantry">
				<Parallax
					className="background-p"
					y={["-250px", "250px"]}
					tagOuter="figure"
				>
					<img src="./img/pitchn-tilted.svg" alt="" />
				</Parallax>
				<div className="main-title-container">
					<div className="title-section">
						<div className="title-top">
							<span>
								<img
									style={{ margin: "0 5px 5px 0" }}
									src="../img/heart.svg"
									alt=""
								/>
							</span>
							Share what you can spare
						</div>
						<div className="title-middle">
							Sharing is
							<br />
							Caring
						</div>
						<div className="title-bottom">
							Share critical items with
							<br />
							others in your community
							<br />
							to help someone in real need!
						</div>
					</div>
					<div className="title-image">
						<div>
							<img src="../img/sharing.png" alt="" />
						</div>
					</div>
				</div>

				<div className="instructions">
					<div className="instruction-block">
						<div className="instruction-aside"></div>
						<div className="instruction-title">
							Pantry
							<div className="instruction-subtitle">
								Learn how to upload an item and acquire an item from the pantry
								feature
							</div>
							<div className="background-title">Pantry</div>
						</div>
						<div className="instruction-steps">
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img src="./img/create-list-1.png" alt="Add itens to pantry" />
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										Add Item(s) to Pantry to <br /> share with a person in{" "}
										<br />
										need.
									</span>
									<br />
									Take a photo of the item <br />
									and provide important info
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/connect.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										Connect with a person <br /> that needs the items you <br />
										listed to share
									</span>
									<br />
									Users can search for items needed.
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/messaging.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										Make arrangements to <br /> exchange the item(s)
									</span>
									<br />
									Use messaging to make meeting up seamless.
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/reimburse-safely.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">Exchange Item(s)</span> <br />
									Wear masks and maintain social distance at all times.
								</div>
							</div>
						</div>
					</div>
				</div>
				<PantryDescription />
				{/* <div className="instruction-video">
					<div className="video-aside">
						<div className="video-description">
							Note : Items uploaded on PANTRY are to be free of cost (No tips,
							no gratuities) Pantry is for people who would like to share what
							they can spare out of the goodness of their heart- so you don’t
							have to pay for the items your fellow community members have
							ofered to share.
						</div>
					</div>
					<div style={{ width: "520px", height: "330px" }} className=""></div>
				</div> */}

				<div className="pantry-note">
					Note : Items uploaded on PANTRY are to be free of cost (No tips, no
					gratuities). Pantry is for people who would like to share what they can
					spare out of the goodness of their heart- so you don’t have to pay for
					the items your fellow community members have offered to share.
				</div>

				<div className="pantry-questions">
					<div className="question-column">
						<div className="question-item">Do you want to share?</div>
						<div className="answers-list">
							<div className="answer-item">
								<div className="answer-number">#1</div>
								<div className="answer-text">
									Upload a Photo of the item and specify the details you are
									willing to share or donate.
								</div>
							</div>
							<div className="answer-item">
								<div className="answer-number">#2</div>
								<div className="answer-text">
									Connect with the help seeker who will frst send you a message
									to enquire about your item
								</div>
							</div>
							<div className="answer-item">
								<div className="answer-number">#3</div>
								<div className="answer-text">
									Keep the exchange simple and do not ask for any kind of
									reimbursement
								</div>
							</div>
							<div className="answer-item">
								<div className="answer-number">#4</div>
								<div className="answer-text">
									Sanitize every item before handing it over to the help seeker.
								</div>
							</div>
							<div className="note">
								<span
									className="bolder-text"
									style={{
										color: "#fff",
										padding: "0 5px",
										background: "#e85a24",
										position: "absolute",
										top: "-20px",
										left: 0,
									}}
								>
									Note:
								</span>{" "}
								Note: No Payment of any kind or Reimbursement is to be provided
								to a Helper in return for Pantry goods/supplies. These are to be
								shared free of cost.
							</div>
						</div>
					</div>
					<div className="question-column">
						<div className="question-item">Are you looking for something?</div>
						<div className="answers-list">
							<div className="answer-item">
								<div className="answer-number">#1</div>
								<div className="answer-text">
									Navigate to the pantry section of the app
								</div>
							</div>
							<div className="answer-item">
								<div className="answer-number">#2</div>
								<div className="answer-text">
									Search for the item you’re looking for from the list!
								</div>
							</div>
							<div className="answer-item">
								<div className="answer-number">#3</div>
								<div className="answer-text">
									Connect with the helper using the messaging feature of the app
									and make plans to meet up seamlessly
								</div>
							</div>
							<div className="answer-item">
								<div className="answer-number">#4</div>
								<div className="answer-text">
									Wear gloves and sanitize any item you recieve from the helper.
									You do not have to pay for any item you recieve from the
									helper.
								</div>
							</div>
						</div>
						<div className="note">
							<span
								className="bolder-text"
								style={{
									color: "#fff",
									padding: "0 5px",
									background: "#e85a24",
									position: "absolute",
									top: "-20px",
									left: 0,
								}}
							>
								Note:
							</span>{" "}
							Note: No Payment of any kind or Reimbursement is to be provided to
							a Helper in return for Pantry goods/supplies. These are to be
							shared free of cost.
						</div>
					</div>
				</div>

				<div className="faq">
					<div className="common-links">
						<a className="link-item" href="/give-help">
							How can you help?
						</a>
						<a className="link-item" href="/get-help">
							Need help running an errand?
						</a>
						<a className="link-item" href="/">
							What is PitchN?
						</a>
					</div>
					<div className="faq-image">
						<img src="./img/door-delivery.png" alt="" />
					</div>
				</div>
			</div>
		);
	}
}
export default Pantry;
