import React from "react";
import "./styles.scss";

const Econsent = () => (
	<div className="econsent-content">
		<div>
			<div className="title">
				<h1>
					YOU MUST AGREE TO THIS E-SIGN CONSENT TO REGISTER FOR THE PITCHN INC.
					SERVICES.
				</h1>
			</div>
			This E-sign Disclosure and Consent (“E-sign Consent”) applies to all
			Records and Communications for services provided on the Pitchn Inc.
			website, applications, mobile apps, and other tools. “Records and
			Communications” means the Pitchn Inc. User Service Agreement (the “Terms
			of Use”) between you and Pitchn Inc. for the Pitchn Inc. and Pitchn App
			services described in the Service Agreement as well as any modification or
			amendments hereto; any policies, notices or disclosures; and all other
			information that we may be required to provide to you in writing by law.
			<br />
			<ol type="a">
				<li>
					<strong>
						Records and Communications May be Provided in Electronic Form.
					</strong>
					You agree that we may provide you with Records and Communications in
					electronic format. Your consent to receive electronic Records and
					Communications includes, but is not limited to:
					<ul>
						<li>
							All legal and regulatory disclosures and communications for the
							<em>Pitchn Inc.</em> services;
						</li>
						<li>
							Notices or disclosures about a change in the terms governing use
							of the <em>Pitchn Inc.</em> services;
						</li>
						<li>Privacy policies and notices.</li>
					</ul>
				</li>
				<br />
				<li>
					<strong>
						Providing Records and Communications in Electronic Form.
					</strong>
					All Records and Communications to you in electronic form may be
					provided (i) by email, (ii) by access to a website designated in an
					email notice from Pitchn Inc. to you, or (iii) to the extent
					permissible by law, by access to a website generally designated in
					advance for such purpose.
				</li>
				<br />
				<li>
					<strong>How to Withdraw Consent.</strong>
					You may withdraw your consent to receive Records and Communications in
					electronic form by contacting us at{" "}
					<a href="mailto:help@Pitchncares.org" rel="nofollow">
						help@Pitchncares.org
					</a>
					. Once we process your request to withdraw your consent to receive
					Records and Communications in electronic form, your access and use of
					the Pitchn services will terminate. Any withdrawal of your consent to
					receive electronic Records and Communications will be effective only
					after we have a reasonable period of time to process your withdrawal.
				</li>
				<br />
				<li>
					<strong>How to Update Your Information.</strong>
					You are responsible to provide us with true, accurate and complete
					email address, contact and other information related to this E-sign
					Consent and to the Pitchn Inc. services and to maintain and update
					promptly any changes in that information. You can update your
					information through our website.
				</li>
				<br />
				<li>
					<strong>Hardware and Software Requirements.</strong>In order to
					access, view and retain electronic Records and Communications that we
					make available to you, you must have:
					<ul>
						<li>
							An internet browser that we support, which include Internet
							Explorer 10.x or higher, Safari 3.2.x or higher, Firefox 32.x or
							higher and Chrome 38.x or higher, Mac OSX Safari 5 and higher,
							Safari for iOS 6 and higher, Chrome for iOS, and Chrome for
							Android;
						</li>
						<li>
							Local, electronic storage capacity to retain Records or
							Communications or a printer to print them;
						</li>
						<li>
							A valid email account and software to access that email account;
						</li>
						<li>
							An up-to-date device or devices (e.g., computer, tablet, mobile
							phone, etc.) suitable for connecting to the Internet and supported
							by <em>Pitchn Inc.</em>;
						</li>
						<li>
							An operating system capable of receiving, accessing and displaying
							Records and Communications from us in electronic form via
							text-formatted email or access to our website using a supported
							browser
						</li>
					</ul>
				</li>
				<br />
				<li>
					<strong>Requesting Paper Copies.</strong>
					We will send no paper copy of any Records and Communications to you
					unless you request it or we otherwise deem it appropriate to do so.
					You can obtain a paper copy of your electronic Records and
					Communications by printing it yourself or by requesting that we mail
					you a paper copy. To request a paper copy of any particular Records
					and Communication be mailed to you, contact us at{" "}
					<a href="mailto:help@Pitchncares.org" rel="nofollow">
						help@Pitchncares.org
					</a>
					. Please make sure to state that you are requesting a paper copy of
					particular Records and Communications. We do not charge a fee for
					providing a paper copy of Records and Communications.
				</li>
				<br />
				<li>
					<strong>Records and Communications in Writing.</strong>
					All Records and Communications in electronic or paper format from us
					to you will be considered “in writing”. You should print or download
					for your records a copy of this E-sign Consent and any other Records
					and Communications important to you.
				</li>
				<br />
				<li>
					<strong>Federal Law.</strong>You acknowledge and agree that this
					E-sign Consent is being provided by you in connection with a
					transaction affecting interstate commerce that is subject to the
					federal Electronic Signatures in Global and National Commerce Act, and
					that you and Pitchn Inc. both intend that the Act apply to the fullest
					extent possible to validate our ability to conduct business with you
					by electronic means.
				</li>
				<br />
				<li>
					<strong>Termination/Changes.</strong>
					<em>Pitchn Inc.</em> reserves the right, in our sole discretion, to
					discontinue the provision of electronic Records and Communications, or
					to terminate or change the terms and conditions on which we provide
					electronic Records and Communications. We will provide you with notice
					of any such termination or change as required by law.
				</li>
				<br />
				<li>
					<strong>Consent.</strong>You hereby give your affirmative consent to
					receive electronic Records and Communications as described in this
					E-sign Consent. You further agree that your computer or other device
					satisfies the hardware and software requirements specified above and
					that you have provided Pitchn Inc. with a current email address at
					which we may send you electronic Records and Communications.
				</li>
			</ol>
		</div>
	</div>
);

export default Econsent;
