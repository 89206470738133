import React from "react";
import "./styles.scss";
import TagManager from "react-gtm-module";
import Toast from '../Toast';
import { faq } from "../AmbassadorPage/faq-data.json";

class AmbassadorFaqPage extends React.Component {
	state = {
		name: "",
        email: "",
        chapterName: "",
        currentLocation: "",
        requestSuccess: false
    };
    
    componentDidMount() {
		const data = {
            event: "PageView",
			pageDetails: {
				page: "ambassadors-faq",
				pagePath: "/ambassadors-faq",
			},
		};
		TagManager.dataLayer(data);
	}

	setFormInputs = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	applyForVolunteer = () => {
        const { name, email, chapterName, currentLocation } = this.state;
		fetch("https://api.pitchnapp.com/v1/ambassadors", {
			method: "post",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
                "full_name": name,
                "email": email,
                current_location: currentLocation,
                chapter_name: chapterName
            }),
		})
        .then((response) => {
            return response.json();
        }).then((data) => {
            if(data && !data.err) {
                this.setState({
                    name: '',
                    email: '',
                    requestSuccess: true
                }, () => {
                    setTimeout(() => { this.removeToast() }, 3000);
                })
            }
        })
    };
    
    removeToast = () => {
        this.setState({
            requestSuccess: false
        })
    }

	render() {
		return (
			<div className="faq-page">
				<div className="faq-section">
					<div className="title">Frequently Asked Questions</div>
					<div>
						{faq.map((item) => {
							return (
								<div>
									<div className="questions">{item.question}</div>
									<div
										className="answers"
										dangerouslySetInnerHTML={{ __html: item.answer }}
									/>
								</div>
							);
						})}
					</div>
				</div>

				<div className="volunteer-form" id="volunteer-form">
					<div className="app-name">
						<img src="./img/PitchN_logo.svg" alt="" />
						PitchN
					</div>
					<div className="app-form">
						<div className="form-title">Write to Us</div>
						<div>
							<div className="form-label">Full Name: </div>
							<input
								onChange={this.setFormInputs}
								name="name"
								value={this.state.name}
							/>
						</div>
						<div>
							<div className="form-label">Email: </div>
							<input
								onChange={this.setFormInputs}
								name="email"
								value={this.state.email}
							/>
						</div>
                        <div>
							<div className="form-label">Current Location: (City, State, Country) </div>
							<input
								onChange={this.setFormInputs}
								name="currentLocation"
								value={this.state.currentLocation}
							/>
						</div>
                        <div>
							<div className="form-label">Desired PitchNFam Chapter Name: </div>
							<input
								onChange={this.setFormInputs}
								name="chapterName"
								value={this.state.chapterName}
							/>
						</div>
                        {this.state.requestSuccess && <Toast message="Your request has been noted!" />}
                        {/* {this.state.requestSuccess && <div style={{marginBottom: '20px', color: '#29A1D8'}}>
                            Your request has been noted!
                        </div>} */}
						<div>
							<div onClick={this.applyForVolunteer} className="send-mail">
								Apply Now
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AmbassadorFaqPage;
