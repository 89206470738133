import React from "react";
import "./styles.scss";

const PantryDescription = () => (
	<div className="pantry-use">
		<div className="usage-title">
			How to <br />
			use Pantry?
		</div>

		<div className="note">
			<span
				className="bolder-text"
				style={{
					color: "#fff",
					padding: "0 5px",
					background: "#e85a24",
					position: "absolute",
					top: "-20px",
					left: 0,
				}}
			>
				Note:
			</span>{" "}
			Note: No Payment of any kind or Reimbursement is to be provided to a
			Helper in return for Pantry goods/supplies. These are to be shared free of
			cost.
		</div>
		<div className="usage-caption-instructions">
			<div className="usage-caption">
				Community Marketplace
				<br /> for sharing
			</div>
			<div className="usage-instructions">
				Share critical items with others in your community like baby formula,
				diapers, baby wipes, toilet paper etc.
				<br />
			</div>
		</div>
		<div className="pantry-details">
            <div>
                With Pantry, specify details & upload an image of an item you would like
                to share with your community. You agree to share your items for free with
                people in real need.
            </div>
		</div>
		<div className="usage-image">
			<img src="./img/volunteer-shopper.png" alt="" />
		</div>
		{/* <div className="usage-steps">
			<div className="rounded-edge-top"></div>
			<div className="rounded-edge-bottom"></div>
			<span
				style={{
					color: "#f9f7ef",
					fontWeight: 500,
					fontSize: "60px",
					paddingLeft: "50px",
				}}
			>
				Steps
			</span>
			<div className="usage-steps-text">
				If you choose to shop anywhere <br />
				besides Kroger or Walmart, you <br />
				can{" "}
				<span style={{ color: "#fff", fontWeight: 500 }}>
					create a list of needed <br />
					items and provide the store <br />
					details
				</span>{" "}
				where such items can be <br />
				purchased. Although you can <br />
				add any items you like to the list, <br />
				you will not be able to look up <br />
				available items from that store <br />
				within the PitchN app, except in <br />
				the case of creating a list for <br />
				Walmart or Kroger.
			</div>
		</div> */}
	</div>
);

export default PantryDescription;
