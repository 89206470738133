import React from "react";
import { Parallax } from "react-scroll-parallax";
import TagManager from "react-gtm-module";
import "./styles.scss";

class GiveHelp extends React.Component {
	componentDidMount() {
		const data = {
			event: "PageView",
			pageDetails: {
				page: "give-help",
				pagePath: "/give-help",
			},
		};
		TagManager.dataLayer(data);
	}

	render() {
		return (
			<div className="give-help">
				<Parallax
					className="background-p"
					y={["-250px", "250px"]}
					tagOuter="figure"
				>
					<img src="./img/pitchn-tilted.svg" alt="" />
				</Parallax>
				<div className="main-title-container">
					<div className="title-section">
						<div className="title-top">
							<span>
								<img
									style={{ margin: "0 5px 5px 0" }}
									src="../img/heart.svg"
									alt=""
								/>
							</span>
							Volunteer with a click of a button
						</div>
						<div className="title-middle">
							Volunteer
							<br />
							in Two Ways
						</div>
						<div className="title-bottom">
							Volunteering has never been easier!
							<br />
							<ol>
								<li>Run errands and make a difference</li>
								<li>Share what you can spare</li>
							</ol>
						</div>
						<div className="volunteer-button">Sign Up to Volunteer</div>
					</div>
					<div className="title-image">
						<div>
							<img src="../img/give-help.svg" alt="" />
						</div>
					</div>
				</div>

				<div className="instructions">
					<div className="instruction-block">
						<div className="instruction-title">
							Volunteer
							<div className="instruction-subtitle">
								Learn how to sign up as a volunteer and run an errand for a
								request on the app
							</div>
							<div className="background-title">Volunteer</div>
						</div>
						<div className="instruction-steps">
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img src="./img/helper.png" alt="Add itens to pantry" />
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										<span style={{ color: "#ee5a24" }}>Download</span> the
										PitchN App <br /> and Login <br />
									</span>
									Available on{" "}
									<span className="bolder-text">
										Google play <br />
										store and Apple Store
									</span>
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/create-list.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										<span style={{ color: "#ee5a24" }}>Agree</span> to Volunteer
									</span>
									<br />
									Agree to terms and conditions and you will be ready to
									volunteer for people around you!
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/volunteer-delivery.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										Search Help Requests <br /> and Run an Errand for <br />{" "}
										someone in need Using
										<span style={{ color: "#ee5a24" }}>
											{" "}
											Pre-Order Pickup lists
										</span>
									</span>
									<br />
									Use <span className="bolder-text">Messaging</span> to make
									meeting up seamless.
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/reimburse.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										Upload your digital receipt <br />
										and Deliver the items to the <br />
										help seeker. <br />
									</span>{" "}
									<br />
                                    No tips, no gratuities
								</div>
							</div>
						</div>
					</div>
					<div className="signup-button">Sign Up to Volunteer</div>
				</div>

				{/* <div className="reimbursement" id="reimbursements">
					<div className="title">
						Reimbursements
						<br />
						(Give Help)
					</div>
					<div className="volunteer-reimbursement">
						<div className="volunteer-tips">
							<div className="title">
								If you use PitchN to GIVE HELP (Volunteer to help someone using
								the PitchN App)
							</div>
							<div className="volunteer-points">
								<div className="point-label">
									<div className="number-bubble">#1</div>
									<br />
									Running non-prepaid errands
								</div>
								<div
									style={{
										fontWeight: 500,
										fontSize: "20px",
										color: "#000",
										width: "90%",
										lineHeight: "25px",
									}}
								>
									A volunteer is entitled to receive full reimbursement
									(including applicable taxes) from the person that you helped
									if you pay for items on behalf of that individual.
								</div>
								<div className="point-label">
									<div className="number-bubble">#2</div>
									<br />
									Picking up non-prepaid items
								</div>
								<div
									style={{
										fontWeight: 400,
										fontSize: "18px",
										color: "#666666",
										width: "90%",
										lineHeight: "25px",
									}}
								>
									*PitchN is not responsible for reimbursement. You must
									coordinate this with the person that you help to receive your
									reimbursement once you have delivered the requested items and
									uploaded a valid digital receipt.
								</div>
							</div>
							<div className="pitchn-disclaimer">
								<img src="../img/info-icon.svg" alt="" />
								<br />
								PitchN does not allow for direct reimbursement through the app.
								All reimbursements are between a PitchN user requesting help and
								the PitchN volunteer that helped that individual user.{" "}
								<a style={{ color: "#666666" }}>Please see Terms of Use</a>
							</div>
						</div>
						<div className="tips-disclaimer">
							<div
								style={{
									fontWeight: 500,
									color: "#66c666",
									marginBottom: "30px",
								}}
							>
								Tips
							</div>
							<div className="tips-points">
								Tips and gratuities are not included as this service is done out
								of the goodness of each PitchN volunteer’s heart.
							</div>
							<div className="tips-points">
								Reimbursement is not required if the Pre-Order Pick-up was
								prepaid.
							</div>
							<div className="tips-points">
								Reimbursement is not associated with the use of the Pantry
								feature. Pantry feature enables the sharing of critically needed
								items.
							</div>
						</div>
						<div className="payment-methods">
							<div className="title">
								But we made it as
								<br /> easy as possible
							</div>
							<div className="options">
								Using deep-linking technology, we make it seamless to use a p2p
								payment app already installed on your device. If you use PayPal,
								Venmo, or PayTM be able to access your specifed app to reimburse
								a Volunteer Helper.
							</div>
						</div>
						<div className="reimbursement-steps">
							<div className="rounded-edge-top"></div>
							<div className="rounded-edge-bottom"></div>
							<span
								style={{
									color: "#fff",
									fontWeight: 500,
									fontSize: "60px",
									paddingLeft: "50px",
								}}
							>
								Steps
							</span>
							<div className="volunteer-steps">
								<div className="steps">
									<div
										style={{
											color: "#ee5a24",
											fontSize: "20px",
											fontWeight: 500,
										}}
									>
										Once you, as a volunteer, is approved to run an errand or
										pick-up a Pre-Order, you will upload a digital receipt for
										the order upon delivery of the items.
									</div>
									<div className="step-item">
										<div>
											<div className="number-bubble">#1</div>
										</div>
										<div className="step-text">
											Upload digital receipt for your shopping list or pre-order
											pick-up upon delivery
										</div>
									</div>
									<div className="step-item">
										<div>
											<div className="number-bubble">#2</div>
										</div>
										<div className="step-text">
											Deliver items to the individual you agreed to help
										</div>
									</div>
									<div className="step-item">
										<div>
											<div className="number-bubble">#3</div>
										</div>
										<div className="step-text">
											Coordinate with the individual you helped to receive your
											full reimbursement based on the total amount including
											applicable taxes as stated in the digital receipt.
										</div>
									</div>
								</div>
							</div>
							<div className="acceptable-payment">
								<span
									style={{ color: "#fff", fontSize: "20px", fontWeight: 500 }}
								>
									Acceptable forms of reimbursement are:
								</span>
								<br />
								<div className="payment-options">
									P2P payment platform (Venmo, PayPal, PayTM)
								</div>
								<div className="payment-options">Cash</div>
								<div className="payment-options">Personal Check</div>
							</div>
						</div>
					</div>
				</div> */}
				<div className="faq">
					<div className="common-links">
						<a className="link-item" href="/pantry">
							What is Pantry?
						</a>
						<a className="link-item" href="/get-help">
							Need help running an errand?
						</a>
						<a className="link-item" href="/">
							What is PitchN?
						</a>
					</div>
					<div className="faq-image">
						<img src="./img/door-delivery.png" alt="" />
					</div>
				</div>
			</div>
		);
	}
}

export default GiveHelp;
