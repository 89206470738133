import React from "react";
import TagManager from "react-gtm-module";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.scss";
import { faq } from "./faq-data.json";
import { volunteers } from './volunteers.json';

class AmbassadorPage extends React.Component {
	state = {
		questionNumber: 1
    };
    
    componentDidMount() {
		const data = {
            event: "PageView",
			pageDetails: {
				page: "ambassadors",
				pagePath: "/ambassadors",
			},
		};
		TagManager.dataLayer(data);
	}

	findAnswer = (questionNumber) => {
		return { __html: faq.find((item) => item.id === questionNumber).answer };
    };
    
    setQuestionNumber = (questionNumber) => {
        this.setState({ questionNumber })
    }

	render() {
		return (
			<div className="ambassador-page">
				<div className="main-title-container">
					<div className="title">JOIN OUR #PITCHNFAM</div>
					<div className="sub-title">
						PitchN makes it easy to give help during the Covid-19 crisis and we
						need some <br />
						amazing people like{" "}
						<span className="like-you" style={{ fontStyle: "italic", textDecoration: "underline", fontWeight: 'bold' }}>
							YOU
						</span>{" "}
						to spread the word with us!
					</div>
				</div>
				<div className="section-break">
					<div className="apply-text">
						With your help, we can make a real difference in the fight against
						corononavirus
					</div>
					<div onClick={() => window.location.href="/ambassadors-faq#volunteer-form"} className="apply-button">Apply Now</div>
				</div>

				<div className="chief-ambassadors">
					<div className="chief-ambassador-inner">
						<div className="title">
							We’re looking for
							<br />
							<span style={{ color: "#ee5a24" }}>
								#PitchNFam Chief Ambassadors
							</span>
						</div>
						<div className="description">
							By yourself, you can only help so many people individually, but as
							a community group you can create real impact and that’s why we
							need your help. Use your infuence, network and community
							connections to spread the word and find awesome volunteers like
							you to join your #PitchNFam group. We are looking for outgoing
							people who are known to be the change they want to see in the
							world. This is an opportunity to run an errand for those in need
							and help fght the covid-19 crisis.
							<span className="bolder-text">
								If that is YOU, you’re in the right place!
							</span>
						</div>
					</div>
				</div>

				<div className="chief-responsibilities">
					<div className="chief-section">
						<div className="title">
							Become the PitchN{" "}
							<span className="title-underline">University</span> Chief
						</div>
						<div className="description">
							As the University chief, you will be the face of your University
							PitchN group and a part of the bigger #PITCHNFAM. You will be
							responsible for signing up as many volunteers to sign up and
							download the app to help people in need around them. PitchN will
							set you up with a facebook group where you can promote the app and
							record every good errand run by any of the members in the group.
						</div>
						<div onClick={() => window.location.href="/ambassadors-faq#volunteer-form"} className="apply-button">Apply Now</div>
					</div>
					<div className="chief-section">
						<div className="title">
							Become the PitchN{" "}
							<span className="title-underline">Community</span> Chief
						</div>
						<div className="description">
                            As the Community chief, you will be the face of your Community 
                            PitchN Group and a part of the bigger #PITCHNFAM. You will be 
                            responsible for signing up as many volunteers to sign up and 
                            download the app to help people in need around them. PitchN will
                            set you up with a facebook group where you can promote the app 
                            and record every good errand run by any of the members in the group.
						</div>
						<div onClick={() => window.location.href="/ambassadors-faq#volunteer-form"} className="apply-button">Apply Now</div>
					</div>
				</div>

				<div className="make-difference">
					<div className="title">
						How you can make a difference
						<br />
						as a <span style={{ color: "#ee5a24" }}>#PitchNFam Ambassador</span>
					</div>
					<div className="help-methods">
						<div className="">
							<div>
								<img src="./img/red-heart.svg" alt="" />
							</div>
							Recruit As Many People As You Can To Help Others During This
						</div>
						<div>
							<div>
								<img src="./img/errands.svg" alt="" />
							</div>
							Run Errands To Help Keep People Safer At Home
						</div>
						<div>
							<div>
								<img src="./img/share-spare.svg" alt="" />
							</div>
							Share What You Can Spare
						</div>
					</div>
				</div>

				<div className="get-acquainted">
					<div className="title">Get Acquainted</div>
					<div className="content">
						<div className="content-top">
							<div className="content-item">
								<div className="content-image">
									<img src="./img/download-icon.svg" alt="" />
								</div>
								Download the PitchN App
							</div>
							<div className="content-item">
								<div className="content-image">
									<img src="./img/tick.svg" alt="" />
								</div>
								Apply and become an ambassador
							</div>
							<div className="content-item">
								<div className="content-image">
									<img src="./img/fb-grp.svg" alt="" />
								</div>
								Ambassadors are made admins for their #PITCHNFAM Facebook group
							</div>
						</div>
						<div className="content-bottom">
							<div className="content-item">
								<div className="content-image">
									<img src="./img/plus-one.svg" alt="" />
								</div>
								Invite and recruit volunteers locally to download the PitchN App
							</div>
							<div className="content-item">
								<div className="content-image">
									<img src="./img/red-heart-light.svg" alt="" />
								</div>
								With PitchN App, Help as many people as possible!
							</div>
						</div>
					</div>
				</div>

				<div className="faq-section">
					<div className="faq-content">
						<div className="title">Frequently Asked Questions</div>
						<div className="q-and-a">
							<div className="questions">
								<ul>
									{faq.map((item) => {
										return (
											<li key={item.id} onClick={() => this.setQuestionNumber(item.id)}>
												<div>{item.question}</div>
												<hr></hr>
                                                {this.state.questionNumber === item.id &&<div className="answer-miniscreen">
                                                    <div
                                                        dangerouslySetInnerHTML={this.findAnswer(
                                                            this.state.questionNumber
                                                        )}
                                                    />
                                                </div>}
											</li>
										);
									})}
								</ul>
							</div>
							<div className="answers">
								{/* {faq.find(
									(item) => (item.id === this.state.questionNumber).question
								)} */}
								<div className="answer-container">
									<div
										dangerouslySetInnerHTML={this.findAnswer(
											this.state.questionNumber
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="rewards">
					<div className="title">Rewards</div>
					<div className="content">
						<div>
							<div className="description">
                                "As soon as I realized how much of an impact I could have on my community, 
                                I jumped at the chance to join the PitchNFam. At PitchN, I am not only able 
                                to help on an individual basis as a volunteer, but can oversee and lead my 
                                community's efforts in protecting our most vulnerable neighbors as an 
                                ambassador. It's through this role that I can see first-hand how my efforts 
                                are creating positive change to my city.""
							</div>
							<div className="reward-item2 small-text">
								<div>
									<img src="./img/star-tilted.svg" alt="" />
								</div>
								<div style={{ marginLeft: "40px" }}>
									NETWORK WITH AMBASSADORS ALL OVER THE COUNTRY
								</div>
							</div>
							<div className="reward-item2 small-text">
								<div>
									<img src="./img/star-tilted.svg" alt="" />
								</div>
								<div style={{ marginLeft: "40px" }}>
									EXPLORE BEING A VOLUNTEER INFLUENCER
								</div>
							</div>
							<div className="reward-item2 small-text">
								<div>
									<img src="./img/star-tilted.svg" alt="" />
								</div>
								<div style={{ marginLeft: "40px" }}>
									EXPLORE THE BENEFITS OF BEING A VOLUNTEER
								</div>
							</div>
						</div>
						<div className="reward-column">
							<div className="reward-item">
								<div>
									<img src="./img/green-star.svg" alt="" />
								</div>
								<div>#YOUEVERYWHERE</div>
								<div>
									<img src="./img/green-star.svg" alt="" />
								</div>
							</div>
							<div className="reward-tiers">
								50 volunteers = Social Media Shoutout
							</div>
							<div className="reward-item">
								<div>
									<img src="./img/green-star.svg" alt="" />
								</div>
								<div>WE WILL DESIGN FOR YOU!</div>
								<div>
									<img src="./img/green-star.svg" alt="" />
								</div>
							</div>
							<div className="reward-tiers">
								250 Volunteers = Marketing Material Design
							</div>
						</div>
						<div className="reward-column">
							<div className="reward-item">
								<div>
									<img src="./img/green-star.svg" alt="" />
								</div>
								<div
									style={{
										position: "relative",
										width: "100%",
										height: "50px",
									}}
								>
									<img className="masks-image" src="./img/masks.png" alt="" />
								</div>
								<div>
									<img src="./img/green-star.svg" alt="" />
								</div>
							</div>
							<div className="reward-tiers">100 Volunteers = PitchN Swag</div>
							<div className="reward-item">
								<div>
									<img src="./img/green-star.svg" alt="" />
								</div>
								<div>
									YOU ARE A REAL
									<br />
									CHANGEMAKER
								</div>
								<div>
									<img src="./img/green-star.svg" alt="" />
								</div>
							</div>
							<div className="reward-tiers">
								1000+ Volunteers = Build a Free App with Tangent One
							</div>
						</div>
					</div>
				</div>

				<div className="community-chief">
					{/* <div></div>
					<div className="chief-details">
						<div>
							Meet Our <span style={{ color: "#000" }}>Jersey City</span> PitchN
							Community Chief
							<br />
							<span style={{ fontSize: "50px" }}>Jake R.</span>
						</div>
						<div className="chief-reward">
							“As soon as I realized how much of an impact I could have 
                            on my community, I jumped at the chance to join the PitchNFam. 
                            At PitchN, I am not only able to help on an individual basis 
                            as a volunteer, but can oversee and lead my community's efforts 
                            in protecting our most vulnerable neighbors as an ambassador. 
                            It's through this role that I can see first-hand how my efforts 
                            are creating positive change to my city.”
						</div>
						<div
							style={{
								color: "#29a2d9",
								textDecoration: "underline",
                                marginTop: "50px",
                                cursor: 'pointer'
                            }}
                            onClick={() => window.location.href="/ambassadors-faq#volunteer-form"}
						>
							Wanna get Involved? >
						</div>
					</div> */}
                    <div className="community-chief-title">
                        Meet our PitchN Community Chiefs
                    </div>
                    <Carousel 
                        showStatus={false}
                        showIndicators={false}
                        showArrows={true}
                        >
                            {volunteers.map((chief) => (
                                <div className="chief-details">
                                    <div className="chief-dp">
                                        <img src={chief.imageUrl} alt="" />
                                    </div>
                                    <div className="chief-text">
                                        <div className="chief-name">
                                            {chief.name}<br/>
                                            <span style={{color: '#000', fontWeight: 400}}> - {chief.title}</span>
                                        </div>
                                        <div className="chief-quote">
                                            {chief.quote}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        {/* <div className="chief-details">
                            <div className="chief-dp">

                            </div>
                            <div className="chief-text">
                                <div className="chief-name">
                                    Jake Ritter<br/>
                                    <span style={{color: '#000', fontWeight: 400}}>- Director of Volunteerism, New York Community Chief</span>
                                </div>
                                <div className="chief-quote">
                                    “As soon as I realized how much of an impact I could have 
                                on my community, I jumped at the chance to join the PitchNFam. 
                                At PitchN, I am not only able to help on an individual basis 
                                as a volunteer, but can oversee and lead my community's efforts 
                                in protecting our most vulnerable neighbors as an ambassador. 
                                It's through this role that I can see first-hand how my efforts 
                                are creating positive change to my city.”
                                </div>
                            </div>
                        </div> */}
                    </Carousel>
				</div>

				<div className="pitchn-together" onClick={() => window.location.href="/ambassadors-faq#volunteer-form"}>
					PITCHN & LET’S MAKE A DIFFERENCE TOGETHER
				</div>
				<div className="help-ready">
					<div className="help-description">
						<div>
							<div className="title">Ready to Help?</div>
							<div className="description">
								We want to hear from you. <br />
								Fill out the form & let’s get started!
							</div>
						</div>
						<div>
							<div onClick={() => window.location.href="/ambassadors-faq#volunteer-form"} className="apply-button">Apply Now</div>
							{/* <div className="apply-button">
								<span style={{ marginRight: "10px" }}>
									<img src="./img/share-icon.svg" alt="" />
								</span>
								Share
							</div> */}
						</div>
					</div>
					<div className="volunteers-image">
						<img src="./img/volunteers.png" alt="" />
					</div>
				</div>
			</div>
		);
	}
}

export default AmbassadorPage;
