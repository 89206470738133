import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

class VideoModal extends React.Component {
	state = {
		open: false,
	};
	onOpenModal = () => {
        // alert("called open")
        if(this.state.open) {
            this.setState({ open: false })
        } else {
            this.setState({ open: true });
        }
	};
	onCloseModal = () => {
        // alert("called closed")
		this.setState({ open: false });
	};

	render() {
        const { open } = this.state;
		return (
			<div
				className="explainer-video-container"
				onClick={this.onOpenModal}
			>
				<img
					className="explainer-video"
                    src={this.props.imgUrl}
                    style={this.props.style}
					alt=""
				/>
				{this.props.showPlayButton && <img className="play-button" src="./img/play-button.png" alt="play" />}
				<Modal
					classNames={{ modal: "video-popup" }}
                    open={open} 
                    onClose={this.onCloseModal}
					center
				>
					<div className="pitchn-video-container">
						<iframe
							width="560"
							height="315"
							title="pitchn-video"
                            // src="https://www.youtube.com/embed/ilzjhLR0WSA"
                            src={this.props.videoUrl}
							frameborder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></iframe>
					</div>
				</Modal>
			</div>
		);
	}
}

export default VideoModal;