import React from "react";
import "./styles.scss";

const Footer = (props) => {
	return (
		<footer className="footer">
			<div className="footer-content">
				<div>
					<div className="footer-title">PitchN</div>
					<p>
						<a href="/">Home</a>
					</p>
					<p>Features</p>
					<p className="sub-features">
						<a href="/#block_2">Give Help/Volunteer</a>
					</p>
					<p className="sub-features">
						<a href="/#block_1">Need Help</a>
					</p>
					<p className="sub-features">
						<a href="/#block_3">Pantry</a>
					</p>
					<p>
						<a href="/safety">Safety</a>
					</p>
					<p>
						<a href="/give-help#reimbursements">Reimbursements</a>
					</p>
				</div>
				<div>
					<div className="footer-title">Community</div>
					<p>Blog</p>
					<p>Testimonials</p>
					<p>Help & Support</p>
					<p>Join PitchN Groups</p>
					<p>Write a review</p>
				</div>
				<div>
					<div className="footer-title">Company</div>
					<p><a href="https://www.pitchncares.org/">About PitchN Inc</a></p>
					<p>About PitchN App</p>
					<p>Press</p>
					<p>Careers</p>
					<p>Legal</p>
					<p>
						<a href="/privacy-policy">Privacy Policy</a>
					</p>
					<p>
						<a href="/terms-of-service">Terms of Service</a>
					</p>
					<p>
						<a href="/econsent">eConsent</a>
					</p>
					<p>
						<span
							style={{ cursor: "pointer" }}
							onClick={() => props.openContactForm()}
						>
							Contact Us
						</span>
					</p>
				</div>
				<div className="footer-right">
					<div className="footer-title">Technology that makes a difference</div>
					{/* <div className="footer-notes">
                        <a href="/privacy-policy">Privacy</a>
                        <a href="/terms-of-service">Terms</a>
                        <a href="/econsent">eConsent</a>
                        <a href="/"><img src="/img/pitchn-footer.svg" alt="" /></a>
                    </div> */}
				</div>
			</div>
			{/* <div className="footer-notes-mobile">
                <a href="/privacy-policy">Privacy</a>
                <a href="/terms-of-service">Terms</a>
                <a href="/econsent">eConsent</a>
                <a href="/"><img src="/img/pitchn-footer.svg" alt="" /></a>
            </div> */}
		</footer>
	);
};

export default Footer;
