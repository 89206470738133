import React from "react";
import { Parallax } from "react-scroll-parallax";
import TagManager from "react-gtm-module";
import "./styles.scss";
import PantryDescription from "./PantryDescription";
import PreOrderPickups from "./PreOrderPickups";
import ShoppingLists from "./ShoppingLists";
import VideoModal from "../HomePage/VideoModal";

const ReadMore = () => (
	<div className="read-more">
		<span>Read More</span>
		<img src="./img/collapse.svg" alt="" />
		<br />
		<span>Read More</span>
	</div>
);

const ReadLess = () => (
	<div className="read-less">
		<span>Read Less</span>
		<img src="./img/expand.svg" alt="" />
		<br />
		<span>Read Less</span>
	</div>
);

class WorkingPage extends React.Component {
	componentDidMount() {
		const data = {
			event: "PageView",
			pageDetails: {
				page: "get-help",
				pagePath: "/get-help",
			},
		};
		TagManager.dataLayer(data);
	}

	state = {
		expandBlock: {
			1: false,
			2: false,
			3: false,
		},
	};

	expandSection = (blockNumber) => {
		this.setState((prevState) => ({
			expandBlock: {
				...prevState.expandBlock,
				[blockNumber]: !prevState.expandBlock[blockNumber],
			},
		}));
	};

	render() {
		const { expandBlock } = this.state;
		return (
			<div className="working-page">
				<Parallax
					className="background-p"
					y={["-250px", "250px"]}
					tagOuter="figure"
				>
					<img src="./img/pitchn-tilted.svg" alt="" />
				</Parallax>
				<div className="main-title-container">
					<div className="title-section">
						<div className="title-top">
							<span>
								<img
									style={{ margin: "0 5px 5px 0" }}
									src="../img/heart.svg"
									alt=""
								/>
							</span>
							Need Help running an errand?
						</div>
						<div className="title-middle">
							Get Help in
							<br />
							TWO easy ways
						</div>
						<div className="title-bottom">
							Get help running critical errands
							<br />
							to minimize your risk of Covid-19 exposure.
							<br />
							<ol>
								<li>Create a pre-order pick-up</li>
								<li>
									Search and find critically needed items people are willing to
									share for free
								</li>
							</ol>
						</div>
					</div>
					<div className="supplies-images">
						<div className="supplies-1">
							<img src="./img/supplies-1.png" alt="" />
						</div>
						<div className="supplies-2">
							<img src="./img/supplies-2.png" alt="" />
						</div>
						<div className="supplies-3">
							<img src="./img/supplies-3.png" alt="" />
						</div>
						<div className="supplies-4">
							<img src="./img/supplies-4.png" alt="" />
						</div>
					</div>
				</div>

				<div className="instructions">
					<div className="instruction-block">
						<div
							className="instruction-aside"
							onClick={() => this.expandSection(1)}
						>
							{expandBlock[1] ? <ReadLess /> : <ReadMore />}
						</div>
						<div
							className="section-details"
							style={{ width: expandBlock[1] ? "100%" : 0 }}
						>
							<PreOrderPickups />
						</div>
						<div className="instruction-title">
							Pre-Order Pickups
							<div className="background-title">Pre-Order Pickups</div>
						</div>
						<div className="instruction-steps">
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/order-pickup.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										Place an order with any retailer
										<br />
									</span>
									Ex. Restaurant, grocery store, pharmacy, supply shop etc.
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/create-list.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										Create a Pre-Order Pick-up
										<br /> Request in PitchN
										<br />
									</span>
									Provide the location of the pick-up and a confrmation number.
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/volunteer-delivery.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										A PitchN Volunteer Helper <br /> picks up and delivers your{" "}
										<br />
										order for free <br />
									</span>
									This way you can stay at home and stay safe from COVID-19.
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/reimburse.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										No tips/ gratuities <br />
									</span>
									<br />
									Make sure you wear a mask and keep your social distance
								</div>
							</div>
						</div>
					</div>
					<div className="instruction-video">
						<div className="video-aside">
							<div className="video-description">
								Follow Step-by-Step
								<br /> Instructions/Video to successfully request
								<br /> for a Pre-Order Pickup
							</div>
							<div
								className="video-button"
								onClick={() => (window.location.href = "/preorder-steps")}
							>
								Detailed Instructions >
							</div>
						</div>
						<div className="video-container">
							<VideoModal
								imgUrl="./img/get-help-video.png"
								videoUrl="https://www.youtube.com/embed/fevCYf9Nkyg"
							/>
						</div>
					</div>
				</div>

				<div className="instructions">
					<div className="instruction-block">
						<div
							className="instruction-aside"
							onClick={() => this.expandSection(3)}
						>
							{expandBlock[3] ? <ReadLess /> : <ReadMore />}
						</div>
						<div
							className="section-details"
							style={{ width: expandBlock[3] ? "100%" : 0 }}
						>
							<PantryDescription />
						</div>
						<div className="instruction-title">
							Pantry
							<div className="background-title">Pantry</div>
						</div>
						<div className="instruction-steps">
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/create-list-1.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										Add Item(s) to Pantry to <br />
										share with a person in need.
									</span>
									Take a photo of the item and provide important info
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/connect.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										Connect with a person <br />
										that needs the items you <br />
										listed to share
									</span>
									<br />
									Users can search for items needed.
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/messaging.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">
										Make arrangements to <br /> exchange the item(s)
									</span>
									<br />
									Use <span className="bolder-text">messaging</span> to make
									meeting up seamless.
								</div>
							</div>
							<div className="instruction-item">
								<div className="instruction-item-image">
									<img
										src="./img/reimburse-safely.png"
										alt="Place order with retailer"
									/>
								</div>
								<div className="instruction-item-text">
									<span className="item-title">Exchange Item(s)</span>
									<br />
									Wear masks and maintain social distance at all times.
								</div>
							</div>
						</div>
					</div>
					<div className="instruction-video">
						<div className="video-aside">
							<div className="video-description">
								Learn how to list and search
								<br /> for critically needed items
								<br /> using Pantry
							</div>
							<div className="video-button">Detailed Instructions ></div>
						</div>
						<div className="video-container">
							<VideoModal
								imgUrl="./img/pantry-video.png"
								videoUrl="https://www.youtube.com/embed/X1xS5p28eRY/"
							/>
						</div>
					</div>
				</div>

				<div className="faq">
					<div className="common-links">
						<a className="link-item" href="/pantry">
							What is Pantry?
						</a>
						<a className="link-item" href="/give-help">
							Want to help? Sign up to become a volunteer
						</a>
						<a className="link-item" href="/">
							What is PitchN?
						</a>
					</div>
					<div className="faq-image">
						<img src="./img/door-delivery.png" alt="" />
					</div>
				</div>
			</div>
		);
	}
}

export default WorkingPage;
