import React from "react";
import "./styles.scss";

const PreOrderPickups = () => (
	<div className="preorder-pickups">
		<div className="pickup-title">
			What are <br />
			Pre-Order <br />
			Pickups?
		</div>
		<div className="pickup-image">
			<img src="./img/door-delivery.png" alt="" />
		</div>
		<div className="pickup-instructions">
			Place an order with any grocery <br />
			store, restaurant, pharmacy, or <br />
			supply shop (any brick and mortar <br />
			retail business). Then, using this <br />
			feature a volunteer Helper will go <br />
			pick up your order and deliver it <br />
			to you free of charge.
		</div>
		<div className="pickup-steps-text">
			Once published, volunteers can view the request and elect to pick up and
			deliver the pre-ordered items to your doorstep.
			<br />
			<div>
				Tip: Keep track of all the important <br />
				information
				<br />
				•Name of business
				<br />
				•Name used to place the order (your <br />
				name)
				<br />
				•Business Address
				<br />
				•Confrmation # for the order (this is <br />
				important)
			</div>
		</div>
        <div className="note">
        <span className="bolder-text note-title" style={{  }}>
            Note:
        </span>{" "}
            To use the Pre-Order Pickup Feature, you must have already placed an order
            with any retailer ahead of creating a new pickup request
        </div>

		<div className="pickup-steps">
			<div className="rounded-edge-top"></div>
			<div className="rounded-edge-bottom"></div>
			<span
				style={{
					color: "#f9f7ef",
					fontWeight: 500,
					fontSize: "60px",
					paddingLeft: "50px",
				}}
			>
				Steps
			</span>
			<div className="step-series">
				<div className="step-item">
					<div className="number-bubble">#1</div>
					<div>Create a New-Order Pickup</div>
				</div>
				<div className="step-item">
					<div className="number-bubble">#2</div>
					<div>Fill out the form by completing all fields</div>
				</div>
				<div className="step-item">
					<div className="number-bubble">#3</div>
					<div>Publish it</div>
				</div>
                <div className="step-item">
					<div className="number-bubble">#4</div>
					<div>Pitchn Volunteers accept your request</div>
                    You can approve the volunteer
				</div>
                <div className="step-item">
					<div className="number-bubble">#5</div>
					<div>PitchN Volunteer picks up and delivers your order to your doorstep</div>
				</div>
			</div>
		</div>
	</div>
);

export default PreOrderPickups;
