import React from 'react';

class ScrollSlider extends React.Component {

    constructor() {
        super();
        this.sliderCanvas = React.createRef();

        this.speedFactor = 1.75;
        this.percent = 0;
        this.direction = 1;
        this.timeout = null;
    }

    animate = () => {
        this.percent += this.speedFactor * this.direction;
        if(this.timeout) {
            // console.log("debounced")
            cancelAnimationFrame(this.timeout);
        }
    
        this.draw(this.percent);
        var self = this;
        if (this.direction > 0 && this.percent <= Math.round(this.props.scrollProgress)) {
            this.timeout = requestAnimationFrame(self.animate);
        } else if (this.direction < 0 && this.percent >= Math.round(this.props.scrollProgress)){
            this.timeout = requestAnimationFrame(self.animate);
        } else {
            // console.log("RESET")
            this.props.resetJumpToFlag();
        }

    }

    draw = (sliderValue) => {

        // redraw path
        const { ctx, slider } = this;
        ctx.clearRect(0, 0, slider.width, slider.height);
        ctx.globalCompositeOperation='destination-over';
        ctx.lineWidth = 5;

    
        ctx.beginPath();
        ctx.moveTo(100, 160);
        ctx.quadraticCurveTo(130, 200, 150, 120);
        ctx.strokeStyle = '#66c666';
        ctx.stroke();
    
        ctx.beginPath();
        ctx.moveTo(150, 120);
        ctx.bezierCurveTo(190, -40, 200, 200, 300, 150);
        ctx.strokeStyle = '#66c666';
        ctx.stroke();

        var xy;

        if (sliderValue < 50) {
            var percent = sliderValue/ 49
            xy = this.getQuadraticBezierXYatPercent({
                x: 100,
                y: 160
            }, {
                x: 130,
                y: 200
            }, {
                x: 150,
                y: 120
            }, percent);
        } else {
            var percent = (sliderValue - 50) / 50
            xy = this.getCubicBezierXYatPercent({
                x: 150,
                y: 120
            }, {
                x: 190,
                y: -40
            }, {
                x: 200,
                y: 200
            }, {
                x: 300,
                y: 150
            }, percent);
        } 
        this.drawDot(xy, "#66c666");
    }
    
    // draw tracking dot at xy
    drawDot = (point, color) => {
        const { ctx } = this;
        ctx.fillStyle = color;
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.arc(Math.min(point.x, 300), point.y, 7, 0, Math.PI * 2, false);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
    }

    // quadratic bezier: percent is 0-1
    getQuadraticBezierXYatPercent = (startPt, controlPt, endPt, percent) => {
        var x = Math.pow(1 - percent, 2) * startPt.x + 2 * (1 - percent) * percent * controlPt.x + Math.pow(percent, 2) * endPt.x;
        var y = Math.pow(1 - percent, 2) * startPt.y + 2 * (1 - percent) * percent * controlPt.y + Math.pow(percent, 2) * endPt.y;
        return ({
            x: x,
            y: y
        });
    }

        // cubic bezier percent is 0-1
    getCubicBezierXYatPercent = ( startPt, controlPt1, controlPt2, endPt, percent) => {
        var x = this.CubicN(percent, startPt.x, controlPt1.x, controlPt2.x, endPt.x);
        var y = this.CubicN(percent, startPt.y, controlPt1.y, controlPt2.y, endPt.y);
        return ({
            x: x,
            y: y
        });
    }

    // cubic helper formula at percent distance
    CubicN = (pct, a, b, c, d) => {
        var t2 = pct * pct;
        var t3 = t2 * pct;
        return a + (-a * 3 + pct * (3 * a - a * pct)) * pct + (3 * b + pct * (-6 * b + b * 3 * pct)) * pct + (c * 3 - c * 3 * pct) * t2 + d * t3;
    }
    
    componentDidMount() {
        this.slider = document.getElementById("slider");
        this.ctx = this.slider.getContext("2d");
        // this.animate();
    }
    
    componentDidUpdate(prevProps) {
        if(this.props.animateScroll && prevProps.scrollProgress !== this.props.scrollProgress) {
            this.percent = Math.floor(prevProps.scrollProgress);
            this.direction = 1;
            if(Math.abs(prevProps.scrollProgress - this.props.scrollProgress) > 50) {
                this.speedFactor = 2.5;
            } else {
                this.speedFactor = 1.75;
            }
            if (prevProps.scrollProgress > this.props.scrollProgress) {
                this.direction = -1;
            }
            this.animate();
        } else {
            this.draw(this.props.scrollProgress*1.1);
            if(this.props.animateScroll) {
                this.props.resetJumpToFlag();
            }
        }

    }
    
    render() {
        return (
            <div className="scroll-slider">
                <canvas id="slider" ref={this.canvasSlider} width="400" height="300"></canvas>
            </div>
        )
    }
}

export default ScrollSlider;