import React from "react";
import "./styles.scss";

const TermsOfService = () => (
	<div className="tos-content">
		<div className="title">
			<h1>PitchN Terms of Use Agreement</h1>
			<h2>Date of last revision: July 13, 2020</h2>
		</div>
		<div>
			This terms of use agreement (the “Agreement”) is made by and between
			Pitchn Inc., a Colorado nonprofit Corporation (“Company”, “Pitchn Inc.”,
			“We”, “Us”, “Our”), and the person or entity (collectively or individually
			“You” or “Your” or “User” or “Users”) (each a “Party”) that has decided to
			use the Pitchn mobile app (the “App”), the website{" "}
			<a href="/">https://www.PitchNapp.com</a> (the “Site”), or any features,
			services, products, graphics, text, images, photos, audio, video, location
			data, computer code, and all other forms of data and communication
			(collectively, “Content”) made available by the Company (together with the
			App and Site, the “Services”).
			<br />
			<br />
			BY USING THE SERVICES IN ANY WAY YOU AGREE TO BE BOUND BY THIS AGREEMENT.
			This applies whether or not you register an account and whether or not you
			ultimately use the Services. If you do not accept and agree to be bound by
			all of the terms of this Agreement, including the{" "}
			<a target="_blank rel=noreferrer noopener" href="/privacy-policy">
				Privacy Policy
			</a>
			, posted at https://www.pitchnapp.com/privacy-policy and incorporated by
			reference herein, do not use the Services.
			<br />
			<br />
			The terms of this Agreement are subject to change by the Company at any
			time. It is your responsibility to check this Agreement periodically for
			any changes. A registered user will be notified automatically of any
			updates to the Agreement upon logging into their account following an
			update. Continued use of the Services shall constitute acceptance of the
			new terms of use agreement.
			<br />
			<br />
			<div className="">
				<h2>1.) Age Restrictions and Safety Age</h2>
				<h3>Age</h3>
				NO USE BY UNDERAGE PERSONS. NO PERSONS UNDER THE AGE OF EIGHTEEN (18)
				YEARS (OR TWENTY-ONE (21) YEARS IN PLACES WHERE EIGHTEEN (18) YEARS IS
				NOT THE AGE OF MAJORITY) MAY DIRECTLY OR INDIRECTLY VIEW, POSSESS OR
				OTHERWISE USE THE PITCHN INC. SERVICES.
				<br />
				YOU MUST BE A LEGAL ADULT. YOU HEREBY REPRESENT AND WARRANT THAT YOU ARE
				CURRENTLY EIGHTEEN (18) YEARS OF AGE OR OVER (OR TWENTY-ONE (21) YEARS
				IN PLACES WHERE EIGHTEEN (18) YEARS IS NOT THE AGE OF MAJORITY) AND YOU
				ARE CAPABLE OF LAWFULLY ENTERING INTO AND PERFORMING ALL THE OBLIGATIONS
				SET FORTH IN THIS AGREEMENT.
				<br />
				<h3>Safety</h3>
				PITCHN INC. IS NOT RESPONSIBLE FOR YOUR USE OF THE SITE OR SERVICES OR
				FOR THE ACTIONS OF OTHER USERS WITH WHOM YOU MAY EXCHANGE INFORMATION OR
				HAVE CONTACT. PITCHN INC. DOES NOT CONDUCT CRIMINAL OR OTHER BACKGROUND
				SCREENINGS OF ITS USERS. PITCHN INC. DOES NOT VERIFY THE INFORMATION
				PROVIDED BY USERS WITH RESPECT TO USERS’ IDENTITY, HEALTH, PHYSICAL
				CONDITION, OR OTHERWISE. PITCHN INC. ALSO IS NOT RESPONSIBLE FOR
				ACTIVITIES OR LEGAL CONSEQUENCES OF YOUR USE IN LOCATIONS WHICH MAY
				ATTEMPT TO CRIMINALIZE OR LIMIT YOUR PERSONAL INTERACTIONS. YOU MUST
				MAKE YOUR OWN INFORMED DECISIONS ABOUT USE OF THE APPLICATION IN YOUR
				LOCATION AND ASSESS ANY POTENTIAL ADVERSE CONSEQUENCES.
				<h3>Warning</h3>
				IMPORTANT DISCLAIMER ABOUT LOCATION DATA. THE SERVICES ARE INTENDED ONLY
				AS PERSONAL, LOCATION-BASED SERVICES FOR INDIVIDUAL USE AND SHOULD NOT
				BE USED OR RELIED ON AS AN EMERGENCY LOCATOR SYSTEM, USED WHILE DRIVING
				OR OPERATING VEHICLES, OR USED IN CONNECTION WITH ANY HAZARDOUS
				ENVIRONMENTS REQUIRING FAIL-SAFE PERFORMANCE, OR ANY OTHER APPLICATION
				IN WHICH THE FAILURE OR INACCURACY OF THAT APPLICATION OR THE SERVICES
				COULD LEAD DIRECTLY TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR
				PROPERTY DAMAGE. THE APP SERVICES IS NOT SUITED OR INTENDED FOR FAMILY
				FINDING PURPOSES, FLEET TRACKING, OR ANY OTHER TYPE OF BUSINESS OR
				ENTERPRISE USE.
				<h2>2.) Services</h2>
				<h3>Description of Services</h3>
				The Services are a technology platform that enables Users of the App to
				login with either Facebook, Google respective login credentials, or to
				register an account with Pitchn Inc. to interact with other Users to
				give or to receive help during the COVID-19 global health crisis.
				<br />
				<br />
				An individual in need of help (“Help Seeker”) may create a help list of
				items needed, or may elect to publish a pre-order pickup. Published help
				lists and published pre-order pickup lists become visible to volunteers
				(“Service Provider”, “Helper”, “Volunteer”), who can then elect to
				fulfil a selected list on behalf of the Help Seeker that published it. A
				Helper provides the service of running errands on behalf of a Help
				Seeker such as shopping for groceries, picking up medication, and
				fulfilling other urgently needed help requests.
				<br />
				<br />
				The Services provide the ability to give help or to receive help by
				connecting a Helper with a Help Seeker. The App streamlines the
				connection using geolocation in order to connect Service Providers with
				those seeking services (Users) based on location proximity. In-app
				messaging allows a Helper to communicate with a Help Seeker to make
				arrangements to fulfill a help request, deliver items, and if applicable
				coordinate reimbursement.
				<br />
				<br />
				There are two primary features for Help Seekers – Need Help or Panty.
				<div>
					<br />
					<strong>a. Need Help</strong>
					<br />
					<div>
						<strong>a. Shopping List</strong> is a feature of the App that
						allows those seeking help (“Help Seekers”) to create a list of items
						critically needed that fall under one of the following categories:
						Groceries, Medication, and Critical Supplies. The Help Seeker
						selects one or more categories in which they require help and add
						items to their Shopping List. The categorical list is then published
						so that a Helper can view it and elect to fulfill and delivery the
						listed items to a Help Seeker.
						<br />
						<strong>b. Pre-Order Pickup</strong> is a feature of the App that
						allows a Help Seeker to request a pickup and delivery of a
						pre-arranged order that is also prepaid. The order is then picked-up
						by a volunteer Helper and delivered to the Help Seeker that created
						the Pre-Order pickup. Each Pre-Order Pickup requires specific fields
						to be filled out before the list can be published. Once published,
						volunteers in close geographical proximity are alerted about the
						time-sensitive order.
					</div>
					<br />
					<strong>b. Pantry</strong> is a feature that allows for the sharing of
					critical goods and supplies locally by creating a community
					marketplace for sharing. A Helper is able to list a critical good, or
					supply and its respective quantity publicly so that a Help Seeker can
					arrange to receive it. One example of a pantry item might be baby
					formula or toilet paper.
				</div>
				<br />
				There are two primary features for Helpers (Volunteers)– Give Help or
				Panty.
				<div>
					<br />
					<strong>a. Need Help</strong>
					<br />
					<div>
						<strong>a. Fulfill Help List –</strong> once a Help List is
						published, a volunteer can agree to fulfill it by shopping for the
						requested items and delivering said items to a Help Seeker.
						<br />
						<strong>b. Fulfill Pre-Order Pickup – </strong> Once a Help Seeker
						publishes a Pre-Order Pickup, a volunteer Helper can elect to pickup
						the order and deliver it to a Help Seeker.
					</div>
					<br />
					<strong>b. Pantry</strong> is a feature that allows for the sharing of
					critical goods and supplies locally by creating a community
					marketplace for sharing. A Helper is able to list a critical good, or
					supply and its respective quantity publicly so that a Help Seeker can
					arrange to receive it. One example of a pantry item might be baby
					formula or toilet paper.
				</div>
				<br />
				<strong>License</strong>
				<br />
				The Company grants you a limited, nonexclusive, revocable,
				non-sublicensable, nontransferable license to: (i) download and install
				a copy of the App on any mobile device or computer that you own, (ii)
				access and view any of the Company’s publicly available Content whether
				on the App or Site, and (iii) access and view any Content which you are
				permitted access to whether on the App or Site.
				<h2>3.) Volunteer Service Providers.</h2>
				<h3>Volunteer Status</h3>
				<div>
					ALL SERVICE PROVIDERS ARE VOLUNTEERS. ALL SERVICE PROVIDERS ARE USERS.
					VOLUNTEER USERS OF THE PITCHN SERVICES ARE NOT AFFILIATED WITH PITCHN
					INC. A COLORADO NONPROFIT CORPORATION.
					<br />
					Accordingly, please carefully consider that the Company does not have
					direct control over the Service Provider’s timeliness, quality of
					interaction between Users, suitability as a Service Provider, ability
					to render the services desired, or integrity. The Company makes no
					representations about the suitability of a Service Provider. Neither
					the Company nor its affiliates is responsible for the behavior,
					conduct, or performance of any Service Provider. THE USER, NOT THE
					COMPANY, IS LIABLE AND RESPONSIBLE FOR ANY CLAIM, INJURY OR DAMAGE
					ARISING IN CONNECTION WITH YOUR USE OF THE SERVICES.
					<br />
					Volunteers may be paid reimbursement expenses for their service if
					fulfilling a non-prepaid Shopping List as defined under section 2.
					Description of Services. Reimbursement is detailed under section 6.
					Reimbursement for Expenses.
					<br />
					<br />
					Pitchn Inc. is not responsible for the acts or failures of anyone who
					provides volunteer Services, or to any Users of the Services. In
					addition, Pitchn Inc. is not responsible for any costs or loss that a
					Volunteer or User may incur because of delays or losses caused by
					providing the Services, regardless of who arranged or agreed to
					reimburse you for such services, or because of any act or failure to
					act by any User.
				</div>
				<h2>Your Use of the Services</h2>
				<h3>Your Usage</h3>
				You acknowledge that some of the App Services may only be accessed by
				downloading the App Software to a mobile device.
				<br />
				<br />
				<div>
					<strong>
						THE COMPANY RESERVES THE RIGHT, BUT HAS NO OBLIGATION, TO MONITOR
						ANY USER’S USE OF THE APP SERVICES, INCLUDING A USER’S REGISTRATION
						OR MESSAGING, AS WELL AS A USER’S USE OF OR ACCESS TO THE LOCATION
						INFORMATION AND PROFILES OF OTHER USERS.
					</strong>
					<br />
					<strong>
						ACCORDINGLY, THE COMPANY ALSO RESERVES THE RIGHT TO (A) DISABLE ANY
						USER’S USE OF OR ACCESS TO THE APP SERVICES, INCLUDING THE LOCATION
						INFORMATION OR PROFILES OF OTHER USERS OR (B) TERMINATE ANY USER’S
						ACCOUNT, FOR ANY REASON AND WITHOUT ANY NOTICE OR OUR BEING LIABLE
						TO YOU.
					</strong>
				</div>
				<br />
				<br />
				You alone are responsible for Your involvement with other Users and for
				all content and material that You provide to the Services. You agree
				that Pitchn Inc. will not be responsible for any loss or damage incurred
				as the result of any such interactions. Pitchn Inc. reserves the right,
				but has no obligation, to monitor disagreements between You and other
				Users.
				<br />
				<br />
				Pitchn Inc. does not control the content of User Accounts and profiles.
				Pitchn Inc. has the right, but does not have any obligation, to monitor
				such content for any purpose. You acknowledge that You are solely
				responsible for all content and material that You provide to the
				Services.
				<h3>Your Representations and Eligibility to Use Services</h3>
				You represent and warrant that you : (i) have the authority and capacity
				to enter this Agreement, (ii) if acting on behalf of an entity, that you
				have the authority to act on behalf of that entity, and (iii) you are
				over 18 years of age. You are barred from using the Services if you do
				not meet the above three criteria. The Company reserves the right to
				refuse use of the Services to you and reject, cancel, interrupt, remove
				or suspend any of your use of the Services at any time for any reason
				without liability.
				<h3>Truthfulness of Information</h3>
				All information that you submit when using the Services is complete,
				accurate and truthful, including any information previously submitted to
				a third party account that is later retrieved by linking the Services
				with that third party. You will maintain the completeness, accuracy and
				truthfulness of such information.
				<h3>Limited Use of Services</h3>
				The Services are only for the uses specified in this Agreement. You may
				not use the Services or any Content contained in the Services in
				connection with any commercial endeavors outside of this Agreement. The
				Company reserves the right to investigate and take any available legal
				action in response to illegal and/or unauthorized uses of the Services,
				including any illegal use of Content distributed through the Services
				and unauthorized framing of or linking to the Services.
				<h3>No Infringement</h3>
				You agree that the Company’s Services contain proprietary information
				and material that is owned by the Company and is protected by applicable
				intellectual property and other laws, including but not limited to
				trademark, copyright, patent, and trade secret laws. You agree that you
				will not use such proprietary information or materials in any way
				whatsoever except for use of the Services in compliance with this
				Agreement. No portion of the Services may be reproduced in any form or
				by any means, except as expressly permitted in this Agreement or
				otherwise in writing by an authorized agent of the Company. You agree
				not to modify, rent, lease, loan, sell, distribute, or create derivative
				works based on the Services in any manner, and you shall not exploit the
				Services in any unauthorized way. In no way should your use of the
				Services be construed to diminish the Company’s intellectual property
				rights or be construed as to provide your license or the ability to use
				the Services in any context other than as expressly permitted under this
				Agreement.
				<h3>Prohibited Activities</h3>
				YOU SHALL NOT ENGAGE IN THE FOLLOWING PROHIBITED ACTIVITIES:
				<ol type="a">
					<li>
						Use of the Services for any unlawful activities, whether unlawful
						under state, federal, or local laws;
					</li>
					<li>
						Use of the Services to promote or engage in violence, degradation,
						subjugation, discrimination or hatred against individuals or groups
						based on race, ethnic origin, religion, disability, gender, age,
						veteran status, sexual orientation, or gender identity;
					</li>
					<li>
						You will NOT use the Services or any information displayed within
						the Site to “stalk,” harass, abuse, defame, threaten or defraud
						other Users; violate the privacy or other rights of Users; or
						collect, attempt to collect, store, or disclose without permission
						the location or personal information about other Users;
					</li>
					<li>
						Make any sexual advances or engage in any sexual behavior while
						using the Services. Sexual advances include, but are not limited to,
						any behavior that requests, suggests, or implies any behavior of a
						sexual nature. Sexual behavior includes, but is not limited to,
						kissing, any touching of the breasts, buttocks, lips, or sexual
						organs;
					</li>
					<li>
						Consume any legal psychoactive substance with strict adherence to
						abide by all local, State, and Federal laws concerning the
						consumption of alcohol, tobacco, cannabis, and nicotine, and you
						will limit legal consumption of these products to remain in full
						cognitive capacity in order to be in full and total control of your
						actions;
					</li>
					<li>
						Consume any illegal or illicit drug, pharmaceutical, or
						mind-altering substance, either prior to or during an interaction
						with another User;
					</li>
					<li>Enter the private residence of a User;</li>
					<li>Touch another User:</li>
					<li>
						Invade another User’s safe space equaling a radius of 6 feet or
						greater;
					</li>
					<li>
						Perform any volunteer services including shopping for items, picking
						up items, delivering items, exchanging items, and exchanging money
						or check for reimbursement purposes without a mask or acceptable
						face covering;
					</li>
					<li>Access another user’s account without permission;</li>
					<li>
						Publish or allow to be published malicious code intended to damage
						another any mobile device, browser, computer, server, or network
						hardware;
					</li>
					<li>
						Spam any comments section with offers of goods and servicesor
						inappropriate messages;
					</li>
					<li>
						Provide video, audio photographs, or images of another person
						without his or her permission (or in the case of a minor, the
						minor’s legal guardian);
					</li>
					<li>
						Use manual or automated software, devices, or other processes to
						“crawl,” “scrape,” or “spider” any portion of the Service;
					</li>
					<li>
						Use cryptocurrency, Safe coins, asset backed tokens, or any non-fiat
						currency for a reimbursement payment;
					</li>
					<li>Make profit in addition to reimbursable expenses;</li>
					<li>
						You will NOT use the Services for any commercial or non-private use,
						such as the sale or advertisement of goods or services, and You will
						use the Services for personal, non-commercial use only in the manner
						and for the purposes that We intend;
					</li>
					<li>Charge any fee or fees;</li>
					<li>Charge any tips or gratuities;</li>
					<li>
						Charge a premium or premium price/fee on any or all items exchanged
						in fulfilling a Help List, a Pre-Order Pickup or sharing a Pantry
						item;
					</li>
					<li>
						You will not accept any additional payment, tip, gratuity, barter in
						excess of applicable required reimbursement based upon legitimate
						digital receipt;
					</li>
					<li>
						You will not demand, require, offer, or accept any services and/or
						favors and/or items of any kind in return for help services provided
						through the Site;
					</li>
					<li>
						You will not decompile, reverse engineer, or otherwise attempt to
						obtain the source code of the Services; or
					</li>
					<li>
						Solicit passwords or personal identifying information for commercial
						or unlawful purposes from other users or disseminate another
						person’s personal information without his or her permission.
					</li>
				</ol>
				<h3>Your Responsibilities, Content</h3>
				You represent and acknowledge that you alone are responsible for any
				Content you post, transmit, send, or otherwise provide on or through the
				Services. You shall not post defamatory, inaccurate, abusive, obscene,
				racially offensive, pornographic, harassing, or illegal material, or any
				material that infringes on the rights of any third party.
				<h3>Right to Delete Content</h3>
				The Company reserves the right to delete or remove any of your Content
				from the Services for any reason, with or without notice, depending on
				the nature of the objectionable material and the degree to which it is
				objectionable.
				<h3>Account Security</h3>
				You are responsible for maintaining the confidentiality of the name and
				password you designate during the registration process, and you are
				solely responsible for all activities that occur under your name and
				password. You agree to immediately notify the Company of any disclosure
				or unauthorized use of your name or password or any other breach of
				security at{" "}
				<a href="mailto:help@Pitchncares.org" rel="nofollow">
					help@Pitchncares.org
				</a>{" "}
				and ensure that you log out from your account at the end of each
				session.
				<h3>Incidents</h3>
				If an incident arises during an interaction between Users of the App
				(Helper/Help Seeker), you will report it immediately using the contact
				section of the{" "}
				<a href="https://www.Pitchnapp.com">https://www.Pitchnapp.com</a>{" "}
				website, or alternatively using the Report Abuse feature within the App.
				If the incident is an emergency, first call 911 before reporting the
				incident to the Company.
				<h3>Emergencies</h3>
				If there is an emergency of any kind call 911 immediately.
				<h2>5.) Registration, and Fees </h2>
				<h3>Registering for the Services</h3>
				Registration for the Services occurs upon login using a User’s own
				Facebook, or Google login credentials. By choosing to login using one of
				the above listed logins, you agree to register with the Company.
				Alternatively, you may register a unique account with the App, in which
				you also agree to register with the Company.
				<h3>Fees for Services</h3>
				The App is free to download and free to use.
				<br />
				No fees or premiums are to be incurred, charged, or required to use the
				Services.
				<br />
				<h2>6.) Reimbursement for Expenses</h2>
				WE ARE NOT RESPONSIBLE OR LIABLE FOR ANY PAYMENT OR REIMBURSEMENT ISSUE,
				DISCREPANCY, OR DELINQUENCY THAT MAY ARISE IN CONNECTION WITH USE OF THE
				SERVICES.
				<br />
				<h3>Your Responsibility</h3>
				Any User that publishes a Help List as described is Section 2.
				Description of Services is responsible for providing equitable
				reimbursement to an approved volunteer Service Provider.
				<br />
				<br />
				You will receive a request to approve or reject a volunteer Service
				Provider that offers to fulfill your Help List.
				<br />
				<br />
				If you approve a volunteer’s request to help, you agree to make full
				reimbursement payment to that volunteer Service Provider once a digital
				receipt is uploaded in the App and all the items from the receipt are
				delivered. <br />
				<br />
				You agree to reimburse the approved volunteer Service Provider that
				helped you in the amount equal to the total amount including taxes on
				the digital receipt that the same volunteer Service Provider uploads
				into the App.
				<h3>Acceptable Forms of Reimbursement Payment</h3>
				<ul>
					<li>
						The national fiat currency of the nation in which You are currently
						residing.
						<ul>
							<li>
								For example, if you are currently residing in the USA you must
								use $USD for currency payment.
							</li>
						</ul>
						<li>
							Personal Check from a bank operating in the nation in which you
							are currently residing.
						</li>
						<li>Peer-to-Peer payment platform (Venmo, Paytm, PayPal etc.)</li>
					</li>
				</ul>
				<h3>Volunteer Responsibility</h3>A Volunteer that purchases goods and/or
				supplies for a User (Help Seeker) must upload an image of a valid
				receipt to the App in order to be eligible to receive reimbursement
				payment. Once the Volunteer has uploaded the digital receipt into the
				App and delivered all items based on the digitally uploaded receipt,
				that user can then receive reimbursement via one of the authorized
				methods as detailed above in Acceptable Forms of Payment.
				<br />
				<br />
				<h3>Taxes</h3>
				Reimbursement is inclusive of all taxes, charges, fees, levies, imposts,
				duties, tariffs or other assessments imposed by or payable to any
				federal, state, local or foreign tax or governmental authority,
				including without limitation, sales, use, goods, services, value added,
				consumption, transfer, customs, personal property, stamp duty, excise,
				and other obligations or taxes of the same or similar nature
				(individually and collectively, "Taxes").
				<br />
				<br />
				<strong>No Control Over Reimbursement</strong>
				<br />
				<br />
				<strong>
					No reimbursement payment in connection with the Services between a
					User and a Volunteer, or individuals listed on the App are controlled
					by us, and individuals participating with such do so at their own
					risk.
				</strong>
				<br />
				<br />
				<strong>
					The App is a platform that enables you to communicate, interact and
					connect with other people in your community to request, receive, and
					offer help during the COVID-19 (SARS-CoV-2) health crisis. We cannot
					be responsible for the interactions AND payments AND conduct that you
					have with other individuals on, through, or as a result of your use of
					the Services, so please use good judgment and keep safety in mind when
					using the Services.
				</strong>
				<br />
				<br />
				<h2>7.) Termination</h2>
				<h3>Right and Grounds to Terminate Your Access</h3>
				To protect the Company, and Users (Helpers, and Help Seekers), the
				Company reserves the right to terminate or block your access to the
				Services at any time, with or without cause. Reasons that your access
				may be terminated include but are not limited to:
				<ol type="a">
					<li>Your violation of any of the provisions of this Agreement;</li>
					<li>
						Posting Content that violates this Agreement and refusing to
						immediately remove such Content after notice;
					</li>
					<li>
						Hindering or interfering with the Company in providing its Services;
					</li>
					<li>
						Failing to show proper etiquette to any other User or the Company;
					</li>
					<li>
						Failing to provide payment for the Services in accordance with this
						Agreement;
					</li>
					<li>
						Making misrepresentations, lying or deceiving the Company and in
						doing so inducing the Company to enter into this Agreement, or;
					</li>
					<li>
						Using the Services or Content in violation of any international,
						federal, state, or local law, or applicable regulation, rule, or
						order by a regulatory authority or court of competent jurisdiction.
					</li>
				</ol>
				<h3>Upon Termination</h3>
				The Company, at its sole discretion, with or without notice to you, may:
				(i) terminate your account, while you remain liable for all amounts due
				until and up to the scheduled date of termination; (ii) terminate all
				access to the Services, or (iii) preclude access to the Services (or any
				part thereof). Termination does not relieve you of your obligation to
				pay any fees associated with your use of the Services. To the extent
				there are any payments remaining for the Services, those payments will
				be accelerated and due immediately.
				<br />
				<h3>Survival</h3>
				The provisions on warranty disclaimers, limitation of liability,
				intellectual property, indemnification, and the general provisions shall
				survive termination.
				<br />
				<h2>
					8.) Disclaimer of Warranties, Limitation of Liability, and
					Indemnification
				</h2>
				<h3>Disclaimer of Warranties</h3>
				ALL SERVICES ARE PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTY OF ANY
				KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
				IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
				OR NON-INFRINGMENT. IN ADDITION, THE COMPANY MAKES NO WARRANTY THAT
				ACCESS TO THE SERVICES WILL BE UNITERRUPTED, SECURE, COMPLETE AND ERROR
				FREE, OR UP TO YOUR PARTICULAR REQUIREMENTS.
				<h3>Assumption of Risk</h3>
				You agree to accept all risks related to or arising out of your use of
				the Services, whether online or offline. Risks are not limited to
				dangers inherent to using an electronic device or dangers of theft of
				personal property or bodily harm when in public or private. The Company
				is not responsible for any acts or omissions committed by a third party
				or a volunteer service provider or a help seeking User and you agree to
				and accept such risks and agree that the Company is not responsible.
				<h3>Limitation of Liability</h3>
				THE COMPANY ASSUMES NO LIABILITY AND IS NOT RESPONSIBLE TO YOU OR ANY
				THIRD PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR
				CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER, INCLUDING,
				WITHOUT LIMITATION, THOSE RESULTING FROM USE OF SERVICES PROVIDED BY THE
				COMPANY, WHETHER OR NOT YOU HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
				DAMAGES, AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION
				WITH THE USE OF SERVICES PROVIDED BY THE COMPANY. THE COMPANY ASSUMES NO
				LIABILITY FOR THE ACTIONS BY YOU OR ANY OF THE COMPANY’S USERS, WHETHER
				THE RESULT OF ACCIDENT, NEGLIGENCE, RECKLESSNESS, OR INTENTIONAL DAMAGES
				THAT RESULT FROM ACTIONS BY YOU OR ANY USERS.
				<h3>Indemnification</h3>
				To the fullest extent permitted by law, you shall defend, indemnify, and
				hold harmless the Company, and agents and employees of the Company from
				and against claims, damages, losses and expenses, from any
				misrepresentations made under this Agreement, and also for those
				directly or indirectly arising out of your use of the Service, your
				violation of this Agreement, or the infringement by you or any third
				party using your account of any intellectual property or other right of
				any person or entity. You understand the meaning and legal consequences
				of the representations and warranties made by you herein, and agree to
				indemnify and hold harmless the Company and each of its respective
				affiliates, and each of the respective directors, officers, principals,
				employees, counsel, agents, successors and assigns, if any, of each of
				the foregoing, from and against any and all loss, damage, liability or
				expense (including, without limitation, attorneys’ fees) arising out of,
				due to, relating to, or in connection with, in each case in whole or in
				part, any misrepresentation made by you in connection with this
				Agreement, or any other agreement, instrument, or other document, any
				failure by you to fulfill any of its covenants or agreements set forth
				herein or therein including but not limited to reasonable attorneys’
				fees, arising out of or resulting from performance under this Agreement.
				<h2>9.) Beta Features</h2>
				<h3>Beta Features, Definition</h3>
				Some features of the Service or the entire Service may be identified as
				being new and unsupported alpha or beta programs ("Beta Features").
				<h3>Disclaimer, No Warranties</h3>
				Although the Services are provided “as is” and no warranties or
				guarantees are made with respect to the Services to the fullest extent
				permitted by law, for purposes of clarification it is reiterated here
				that Beta Features are, by definition, new, not-as-well-tested, and more
				susceptible to bugs and errors.
				<h3>Confidential</h3>
				You shall not disclose to any third party any information from Beta
				Features, the existence of non-public Beta Features or access to Beta
				Features. All Beta Features shall be considered the Company’s
				Confidential Information.
				<h2>10.) Third Party Control, Content and Accounts</h2>
				<strong>No Control Over Third Parties</strong>
				<br />
				<br />
				<strong>
					No service interactions, user interactions, exchanges, opportunities,
					meetings, events, organizations, or individuals listed on this Site
					are controlled by us, and individuals participating with such do so at
					their own risk.
				</strong>
				<br />
				<br />
				<strong>
					The Site is a platform that enables you to communicate, interact and
					connect with other people in your community to request/receive or
					offer help during the COVID-19 (SARS-CoV-2) health crisis. We cannot
					be responsible for the interactions that you have with other
					individuals on, through, or as a result of your use of this Site, so
					please use good judgment and keep safety in mind when you use this
					Site.
				</strong>
				<br />
				<br />
				<h3>Third Party Content</h3>
				Our Services display some Content that is not the Company’s, including
				information from and links to third party sites or services. This
				Content is the sole responsibility of the entity that makes it
				available. The Company may review Content to determine whether it is
				illegal or violates the Company’s policies, and the Company may remove
				or refuse to display Content that the Company reasonably believes
				violates the Company’s policies or the law. This does not necessarily
				mean that the Company reviews Content, so please don’t assume that the
				Company does. Always use caution when giving out any personally
				identifying information. Any links or Content provided by the Company is
				only as a convenience, and the inclusion of any such Content does not
				imply endorsement by the Company or any association with its operators.
				<h3>Third Party Services</h3>
				Certain services made available via the Company are delivered by third
				party sites or organizations. By using any product, service, or
				functionality originating from the Site, you acknowledge and consent to
				the Company’s sharing of any information or data required to provide the
				requested product, service, or functionality with any necessary third
				party.
				<h3>Connecting to Third Party Accounts</h3>
				The Company will make efforts to allow you to connect to third-party
				accounts (such as Facebook, Instagram and Google) with the Services.
				<h3>Release to Transfer Information</h3>
				If it is possible to connect your account to a third party account, you
				acknowledge and agree that you are consenting to the continuous release
				of information about you to others (in accordance with your privacy
				settings on those third party sites). If you do not want information
				about you to be shared in this manner, do not use this feature.
				<h3>Accessing the Services Through Third Parties</h3>
				The Services may be accessed or viewed through third-party services that
				are governed by separate terms of service and privacy policies. For
				example, you might download the App to your mobile device through the
				Apple App Store or Google Play Store. The Company has no control over
				these third party services and their content and the third party is not
				a party to this agreement between you and the Company. In no event will
				the Company be liable for your use of any of the products, services, or
				content of such a third party.
				<h2>11.) Notice for California Resident</h2>
				Under California Civil Code Section 1789.3, you are entitled to the
				following consumer rights notice: If you have a question or complaint
				regarding the Company’s Site, please send an email to
				help@pitchncares.org. You may also contact us by writing to enter
				mailing address, or by calling us at enter phone number. California
				residents may reach the Complaint Assistance Unit of the Division of
				Consumer Services of the California Department of Consumer Affairs by
				mail at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone
				at (916) 445-1254 or (800) 952-5210.
				<h2>12.) Notice for International Users</h2>
				<h3>International Users</h3>
				<ol type="a">
					<li>
						Use Outside the United States of America. The Services are
						controlled and offered by PITCHN INC. from the United States of
						America and, regardless of Your place of residence, Your use of them
						is governed by the law of the State of Colorado, USA. PITCHN INC.
						makes no representations that the Services are appropriate for use
						in other locations or are legal in all jurisdictions. Those who
						access or use the Services from other locations do so at their own
						risk and are responsible for compliance with local law. You consent
						to the transfer and processing of Your data in the United States of
						America and any other jurisdiction throughout the world. Please see
						our Privacy Policy for more information on how we collect, use and
						transfer your data.
					</li>
					<li>
						Special Terms. If You are a resident of certain countries, including
						those in the European Union and United Kingdom, You may have
						additional rights or certain parts of this Agreement may not apply
						to You if required by the law of Your jurisdiction. Please refer to
						the Special Terms for International Users for further detail.
					</li>
				</ol>
				<h2>13.) General Provisions</h2>
				<h3>DMCA Violations</h3>
				If you believe any Content on this Site infringes on your copyright, you
				may request removal of the Content by contacting the following address:
				<a href="mailto:help@Pitchncares.org" rel="nofollow">
					help@pitchncares.org
				</a>{" "}
				The Company will respond to all requests as quickly as possible and to
				the best of its ability given its limited resources.
				<h3>Successors and Assignees</h3>
				This Agreement shall be binding upon and shall inure to the benefit of
				you and the Company, and their respective representatives, successors
				and permitted assigns. Users and Service Providers may not assign this
				Agreement for any reason absent the explicit permission of the Company.
				<h3>Arbitration</h3>
				The exclusive means of resolving any dispute or claim arising out of or
				relating to this Agreement (including any alleged breach thereof) or the
				Service shall be BINDING ARBITRATION administered by the American
				Arbitration Association.
				<h3>Class Action Waiver</h3>
				You may not under any circumstances commence or maintain against the
				Company any class action, class arbitration, or other representative
				action or proceeding.
				<h3>Governing Law and Venue</h3>
				With deference to any of the other provisions of this Agreement related
				to arbitration and mediation, this Agreement will be governed by and
				construed in accordance with the laws of the State of Colorado. The
				venue for any disputes including mediation or arbitration shall be
				Boulder, Colorado. Each Party agrees to waive the following defenses to
				any action brought in Colorado: forum non conveniens, lack of personal
				jurisdiction, insufficient process, and insufficient service of process.
				<h3>Waiver</h3>
				If one Party waives any term or provision of this Agreement at any time,
				that waiver will only be effective for the specific instance and
				specific purpose for which the waiver was given. If either Party fails
				to exercise or delays exercising any of its rights or remedies under
				this Agreement, that Party retains the right to enforce that term or
				provision at a later time.
				<h3>Severability</h3>
				If any court determines that any provision of this Agreement is invalid
				or unenforceable, any invalidity or unenforceability will affect only
				that provision and will not make any other provision of this Agreement
				invalid or unenforceable and such provision shall be modified, amended,
				or limited only to the extent necessary to render it valid and
				enforceable.
				<h3>Site Availability</h3>
				<strong>
					PITCHN INC. CANNOT GUARANTEE THE SITE WILL BE AVAILABLE 100% OF THE
					TIME BECAUSE PUBLIC NETWORKS, SUCH AS THE INTERNET, OCCASIONALLY
					EXPERIENCE DISRUPTIONS. ALTHOUGH PITCHN INC. STRIVES TO PROVIDE THE
					MOST RELIABLE WEBSITE AND MOBILE APP REASONABLY POSSIBLE,
					INTERRUPTIONS AND DELAYS IN ACCESSING THE SITE ARE UNAVOIDABLE AND
					PITCHN INC. DISCLAIMS ANY LIABILITY FOR DAMAGES RESULTING FROM SUCH
					PROBLEMS.
				</strong>
				<h3>Force Majeure</h3>
				Neither party shall be responsible for delays or failure in performance
				resulting from acts beyond the control of such party, including without
				limitation, acts of God, strikes, lockouts, riots, acts of war, acts of
				terrorism, epidemics, fire, communication line failures, power surges or
				failures, earthquakes or other disasters.
				<h3>Typographical Errors</h3>
				Information on the Site may contain technical inaccuracies or
				typographical errors. We attempt to make the Site’s postings as accurate
				as possible, but Pitchn Inc. does not warrant the content of the Site is
				accurate, complete, reliable, current, or error-free.
				<h3>Headings</h3>
				Condition and section headings are for convenience of reference only and
				shall not affect the interpretation of these Terms of Use.
				<h3>Entire Agreement</h3>
				This Agreement, together with the Privacy Policy and Service Provider
				Agreement, constitutes the entire agreement between the Company and you,
				and supersedes all prior agreements, representations, and understandings
				between each Party, written or oral.
				<h2>14.) Special Provisions for International Users</h2>
				<h3>Exceptions to Liability Limitations</h3>
				Nothing in this Agreement excludes or limits PITCHN INC.’s (or its
				affiliated companies’, contractors’, subcontractors’, directors’,
				officers’, employees’, agents’, third party suppliers’ or licensors’)
				liability to the extent that it cannot be excluded or limited by
				applicable law.
				<h3>ARBITRATION MAY NOT APPLY TO YOU</h3>
				IF YOU ARE A RESIDENT OF THE EUROPEAN UNION OR UNITE KINGDOM AND SUBJECT
				TO THE ALTERNATIVE DISPUTE RESOLUTION DIRECTIVE (2013/11/EU) AND THE
				ONLINE DISPUTE RESOLUTION REGULATION (EU 524/2013) (AND ANY IMPLEMENTING
				REGULATIONS IN EACH MEMBER STATE OF THE EU), THE AGREEMENT TO ARBITRATE
				IN SECTION 21 WILL NOT APPLY TO YOU BUT THE PROVISIONS OF SECTION 12
				(GENERAL PROVISIONS) WILL APPLY, EXCEPT AS OTHERWISE REQUIRED BY LAW.
				PLEASE READ SECTION 12 CAREFULLY. The European Commission Online Dispute
				Resolution Platform is available at the following link ODR Platform.
				<h3>No Prejudice to Consumer Law</h3>
				As a consumer, You will benefit from any mandatory provisions of the law
				of the country in which You are resident. Nothing in these terms and
				conditions, including, without limitation, Section 12, affects Your
				rights as a consumer to rely on such mandatory provisions of local law.
				<h3>Local Jurisdiction</h3>
				The local law of Your jurisdiction may entitle You to have a dispute
				relating to this Agreement heard by Your local courts, regardless of the
				terms of Section 12, in which We agree that all disputes not subject to
				the terms of Section 12 will be heard in courts located in Boulder,
				Colorado, USA. This Agreement does not limit any such rights that You
				have that apply regardless of the terms of an agreement that You have
				entered into. But by entering into this Agreement or otherwise, PITCHN
				INC. does not consent to the jurisdiction of any courts other than those
				referenced in Section 12 and reserves the right to contest that it is
				not subject to the jurisdiction of any other court.
				<h3>Canada</h3>
				The following terms apply to You, and supersede conflicting terms in
				this Agreement, if You are a Canadian resident to the extent required by
				applicable law:
				<ol>
					<li>
						Dispute Resolution. Section 12 (Arbitration) and/or (Class Action
						Waiver) will not apply to You if any such provision is unenforceable
						under the laws of Your Province of residence. Section 12 (Governing
						Law and Venue). Section 12 will continue to apply in all such cases.
					</li>
				</ol>
				<h3>Australia</h3>
				The following terms apply to You, and supersede conflicting terms in
				this Agreement, if You are an Australian resident to the extent required
				by applicable law:
				<ol>
					<li>
						Transmission of Personal Information Overseas. In consenting to the
						transfer to and processing of Your data in the United States of
						America and any other jurisdiction throughout the world, You
						acknowledge that other jurisdictions (including the United States of
						America) may not have privacy protections equivalent to the Privacy
						Act 1988 (Cth). You may not have a remedy against PITCHN INC. as
						neither the Australian Privacy Principle 8.1 nor Section 16C of the
						Privacy Act will apply.
					</li>
					<li>
						Reverse Engineering. The restriction on the modification,
						disassembly, decompilation or reverse engineering of the Services is
						subject to Your rights under Part III Div 4A (“Acts not constituting
						infringement of copyright in computer programs”) of the Copyright
						Act 1968 (Cth).
					</li>
					<li>
						Consumer Guarantees. PITCHN INC.’s liability for failure to comply
						with any applicable consumer guarantee arising under Part 3-2 Div 1
						of the Australian Consumer Law is limited to:
						<ol>
							<li>
								in the case of goods supplied to You, the replacement of the
								goods or the supply of equivalent goods (or the payment of the
								cost to You of the replacement or supply), or the repair of the
								goods (or the payment of the cost to You of the repair); and
							</li>
						</ol>
					</li>
				</ol>
			</div>
		</div>
	</div>
);

export default TermsOfService;
