import React from 'react';
import './styles.scss';

class ContactForm extends React.Component {
    render() {
        return (
            <div className="contact-form">
                <div className="contact-title">
                    <h1>Contact Us</h1>
                </div>
                <div className="contact-content">
                    Send in your queries to <a href="mailto:help@r2c.io" rel="nofollow">help@r2c.io</a>
                    {/* <div className="contact-item">
                        <div>
                            <label className="contact-label">Name</label>
                        </div>
                        <div>
                            <input />
                        </div>
                    </div>
                    <div className="contact-item">
                        <div className="contact-label">
                            <label>Email Id</label>
                        </div>
                        <div>
                            <input />
                        </div>
                    </div>
                    <div className="contact-item">
                        <div className="contact-label">
                            <label>Your Query</label>
                        </div>
                        <div>
                            <textarea />
                        </div>
                    </div> */}
                </div>
            </div>
        )
    }
}

export default ContactForm;