import React from "react";
import "./styles.scss";

const PreOrderSteps = () => {
	return (
		<>
			<div className="preorder-steps-title">
				Steps to Pre-Order Pickup
				<div className="back-button">
					<a href="/get-help"> &lt;Back</a>
				</div>
			</div>
			<div className="detailed-instructions">
				<div>
					<div className="step-item">
						<span className="step-label">Step 1.</span>
						Place your preorder.
					</div>
					<div className="step-item">
						Place an order with any brick and mortar retailer and *pre-pay if
						possible.<span className="special-note">(i)</span>
					</div>
					<div className="step-item">
						If prepay options are not available, a Help Seeker can agree with a
						volunteer Helper to have the volunteer Helper pay for the order. The
						Help Seeker then agrees to reimburse the volunteer Helper in full
						upon delivery of the order.
					</div>
					<div className="step-item">
						<span className="step-label">Step 2.</span> Create Pickup Request.
						Fill out the form to create a new pickup request. The forms consists
						of fve felds. Please fll out each one individually. Using our lookup
						feature, you will easily be able to fnd the business and its
						address.
					</div>
					<div className="step-item">
						<span className="step-label">Step 3.</span> Publish Request. You can
						easily publish the pickup request you created by hitting the submit
						button. This way, Volunteer Helpers can view it.
					</div>
					<div className="step-item">
						<span className="step-label">Step 4.</span> Approve/Reject Help. A
						Volunteer Helper will ask you to approve their request in ofering to
						help you out by picking up your preorder and then delivering it to
						your residence.
					</div>
					<div className="step-item">
						<span className="step-label">If you Approve – go to Step 5.</span>
					</div>
					<div className="step-item">
						<span className="step-label">
							If you Reject – your Preorder Pickup Request will automatically be
							published so another Helper can elect to fulfll it.
						</span>
					</div>
					<div className="step-item">
						<span className="step-label">Step 5.</span> Make Arrangements to
						Receive Order. Once approved, a Helper will go to the location you
						provided, pickup the order, and then deliver it to your residence
						(while maintaining social distancing). Use our messaging feature to
						coordinate a seamless delivery of your order.
					</div>
					<div className="step-item">
						<span className="step-label">Step 6.</span> Reimburse your Helper.
						If the order was not prepaid, you are responsible for reimbursing
						the volunteer Helper in full, equal to the amount on the digital
						receipt that the Volunteer Helper must submit before. Please see
						Reimbursement for more detailed instructions.
						<span className="special-note">(ii)</span>
					</div>
				</div>
				<div className="preorder-remarks">
					<div className="remark-item-top">
						<span className="special-note">(i)</span>
						<span className="step-label">
							*Pre-pay directly with the retailer, not through the PitchN App.
							Then get a confrmation number and give that to the helper that is
							delivering your pre-paid order.
						</span>
					</div>
					<div className="remark-item-middle">
						<span className="special-note">
							*Please see REIMBURSEMENT section for more detail.
						</span>
					</div>
					<div className="remark-item-bottom">
						<span className="special-note">(ii)Tip: </span>
						<span className="step-label">
							Remember you can always use messaging to deal with any issues that
							may arise. One example if picking up a prescription at a pharmacy,
							you may need to provide the volunteer Helper that is helping you
							with your date of birth.{" "}
						</span>
						<br />
						<br />
						<span className="special-note">Out of Stock </span>
						<span className="step-label">
							{" "}
							- Another example is when an item is out of stock. Use messaging
							to communicate substitutes or if it should be skipped.
						</span>
					</div>
				</div>
				<div></div>
			</div>
		</>
	);
};

export default PreOrderSteps;
