import React from "react";
import TagManager from "react-gtm-module";
import "./styles.scss";

class WhyPitchn extends React.Component {
	componentDidMount() {
		const data = {
			event: "PageView",
			pageDetails: {
				page: "why-pitchn",
				pagePath: "/why-pitchn",
			},
		};
		TagManager.dataLayer(data);
	}

	render() {
		return (
			<div className="why-pitchn">
				<div className="main-title-container">
					<div style={{ fontSize: "26px", fontWeight: 500 }}>Why PitchN?</div>
					<div className="title">
						Help Anyone In Need With <span className="title-name">PitchN</span>
					</div>
					<div className="title-content">
						<div className="title-section">
							<div className="title-item-1">
								<div className="help-icon">
									<img alt="" src="./img/elderly.svg" />
								</div>
								Help the elderly
							</div>
							<div className="title-item-2">
								<div className="help-icon">
									<img alt="" src="./img/immunocompromised2.svg" />
								</div>
								Help the immunocompromised
							</div>
							<div className="title-item-3">
								<div className="help-icon">
									<img alt="" src="./img/hungry.svg" />
								</div>
								Help the hungry
							</div>
							<div className="title-item-4">
								<div className="help-icon">
									<img alt="" src="./img/health-conditions.svg" />
								</div>
								Help those with underlying health conditions
							</div>
						</div>
					</div>
					<div className="bg-imageset"></div>
				</div>

				<div className="why-help">
					<div className="helping-hand">
						<img src="./img/people.png" alt="" />
						<div>
							Lend a helping hand in whatever capacity possible and <br />
							let's work together to make a positive difference.
						</div>
					</div>
					<div className="help-description">
						<div className="help-text">
							Some individuals bear far greater health risk if infected with
							COVID-19 such as the elderly, the immunocompromised, those with
							pre-existing health conditions etc. <br />
							<br />
							The novel coronavirus pandemic impacts us all. It infuences every
							aspect of society from education to commerce. As the virus rages
							on, many individuals and families are currently in need of help.
							And many more will require help in the near future.
						</div>
						<div className="help-image">
							<img src="./img/old.svg" alt="" />
						</div>
					</div>
				</div>

				<div className="download-section">
					<div className="pitchn-description">
						<div style={{ color: "#ed5a24" }}>
							PitchN lets Help seekers <br />
							connect with those who <br />
							are volunteering to help!
						</div>
						<br />
						<div>
							Download the app today <br />
							and fight coronavirus as <br />a community
						</div>
						<div className="download-buttons">
							<span>Download App Now</span>
							<span>Sign Up to Volunteer</span>
						</div>
					</div>
					<div className="pitchn-features">
						<div className="pitchn-features-edge"></div>
						<div className="help-example">
							<div className="example-graphic">
								<img src="./img/toilet-paper.svg" alt="" />
							</div>
							<div className="example-text">
								Have you run out
								<br /> of critical supplies?
							</div>
						</div>
						<div className="help-example">
							<div className="example-graphic">
								<img src="./img/formula.svg" alt="" />
							</div>
							<div className="example-text">
								Do you need baby
								<br /> formula or wipes?
							</div>
						</div>
						<div className="help-example">
							<div className="example-graphic">
								<img src="./img/medicine.svg" alt="" />
							</div>
							<div className="example-text">
								Need help
								<br /> getting medicine?
							</div>
						</div>
						<div className="help-example">
							<div className="example-graphic">
								<img src="./img/groceries.svg" alt="" />
							</div>
							<div className="example-text">
								Need help
								<br /> getting groceries?
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default WhyPitchn;
