import React from "react";
import "./styles.scss";

const PrivacyPolicy = () => (
	<div className="privacy-content">
        <div className="title">
		    <h1>PITCHN APP PRIVACY AND COOKIE POLICY</h1>
        </div>

		<div>
			Welcome to PitchN App’s Privacy and Cookie Policy. This explains how
			Pitchn Inc. a Colorado nonprofit corporation (“PitchN”, “PITCHN” or the
			“Company” or “we” or “us” or “our”) uses, collects, processes, and shares
			personal data, including information obtained through the website{" "}
			<a href="/" target="_blank">
				https://www.pitchnapp.com
			</a>{" "}
			 and{" "}
			<a
				href="https://www.pitchncares.org/"
				target="_blank"
				rel="noopener noreferrer"
			>
				www.Pitchncares.org
			</a>{" "}
			(“PitchN Site” or “Site” or “Website”), the PitchN mobile application
			(PitchN iOS and PitchN Android) (“PitchN App” or the “App”), or any
			features, services, products, graphics, text, images, photos, audio,
			video, location data, computer code, and all other forms of data and
			communication (collectively, “Content”) made available by the Company
			(collectively with the PitchN Site, the PitchN App, as the “PitchN
			Services”).
			<br />
			<br />
			PitchN (pitch in) App was built to centralize and streamline
			people-to-people help during the COVID-19 (SARS-CoV-2) health crisis. The
			PitchN App allows you to share sensitive information about yourself,
			including your precise location, with us (and our necessary service
			providers (“Volunteers”), and other users of PitchN Services. This Privacy
			and Cookie Policy also explains how you can control your personal data. We
			recommend you read this Privacy and Cookie Policy, and our{" "}
			<a href="/terms-of-service" target="_blank" rel="noopener noreferrer">
				Terms of Use
			</a>
			, carefully. You can also use the links below to jump to specific
			information concerning our privacy practices.
			<br />
			<br />
			Note that PitchN Inc. is a Colorado nonprofit corporation. By using the
			PitchN Services, you are transmitting information to the United States. If
			you are a resident of another country, note that the United States may not
			afford the same privacy protections as your country of residence.
			<br />
			<br />
			<ul>
				<li>
					<a href="#personal-data">
						What Personal Data We Collect and Process.{" "}
					</a>
				</li>
				<li>
					<a href="#decison-making">Automated Decision-Making. </a>
				</li>
				<li>
					<a href="#how-we-share-data">How We Share Your Personal Data.</a>
				</li>
				<li>
					<a href="#data-retention">Data Retention. </a>
				</li>
				<li>
					<a href="#how-we-protect-data">How We Protect Your Personal Data. </a>
				</li>
				<li>
					<a href="#your-choices">Your Choices. </a>
				</li>
				<li>
					<a href="#your-rights">Your Rights. </a>
				</li>
				<li>
					<a href="#for-eea-uk-data-subjects">For EEA and/or U.K. Data Subjects. </a>
				</li>
				<li>
					<a href="#for-california-residents">For California Residents. </a>
				</li>
				<li>
					<a href="#cookie-information">Cookie Information. </a>
				</li>
				<li>
					<a href="#additional-information">Additional Information. </a>
				</li>
			</ul>
			<br />
			<h2 id="personal-data">WHAT PERSONAL DATA WE COLLECT AND PROCESS.</h2>
			When you use the PitchN Services, we collect personal data about you.
			“Personal Data” means any information that allows someone to identify you.
			While the following provides examples of the Personal Data that PitchN
			collects, note that not all PitchN Services collect all of the data listed
			below.
			<h3>PERSONAL DATA YOU PROVIDE. </h3>
			<ul>
				<li>
					<strong>Credentials.</strong>In order to use the PitchN Services, you
					are required to provide an Account Authentication Mechanism (email,
					phone number, or a third-party social log-in like Facebook, or
					Google). We use a third-party service provider, SendGrid (Twilio) for
					email verification and authentication and we use Nexmo SMS (Vonage)
					for mobile phone verification and authentication. You can review
					SendGrid’s privacy policy{" "}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.twilio.com/legal/privacy"
					>
						here
					</a>
					. You can review Nexmo’s privacy policy{" "}
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.vonage.com/legal/privacy-policy/"
					>
						here
					</a>
					.{" "}
				</li>
				<li>
					<strong>Community Profile Information.</strong> When you create a
					PitchN App account, you choose to provide us Personal Data about
					yourself for your public PitchN App profile. For example, in the
					PitchN App, you voluntarily provide your Personal Data, such as your
					photo, name, phone number, email address, and any other information
					that you add to your profile on the PitchN App (“Profile
					Information”). You should carefully consider what Personal Data to
					include in your Community Profile, and you can review and change that
					information at any time by accessing the “Edit Profile” section of the
					App.
				</li>
				<li>
					<strong>Feedback/Support.</strong> If you provide us feedback or
					contact customer support, we collect your name and e-mail address and
					possibly other Personal Data (e.g. IP address), as well as any other
					content that you send to us in order to reply and address any issue.
					If you contact customer support, we also collect and retain certain
					technical diagnostic data, such as your phone model. We may keep
					records of our communication with you, including any complaints we
					receive from you about other users (and from other users about you).{" "}
				</li>
				<li>
					<strong>Photos.</strong> If you upload a photo, while using the PitchN
					Services, you will be required to permit The App to access your
					camera, photo gallery, in which case Pitchn Inc. will have access to
					the photos and videos stored in your photo gallery.{" "}
				</li>
				<li>
					<strong>Location and Distance Information.</strong> When you use the
					PitchN App, we collect your precise location (e.g. your latitude and
					longitude) ("Location") to determine your distance in miles or
					kilometers from other users (“Distance Information”). Please see{" "}
					<a href="#data-retention">Data Retention</a> section for information
					on storage of this information. Should you choose not to allow the
					PitchN App to access your Location, certain features (such as
					displaying nearby user profiles for help related features) of the
					PitchN Services will not function properly. You may also revoke this
					permission and disable the location services on your device. You can
					do so on an iPhone by going to Settings, Privacy, Location Services,
					PitchN; and on Android, by going to Settings, Location, PitchN,
					Permissions, Location.{" "}
				</li>
				<li>
					<strong>Messages.</strong> When you send a message using the PitchN
					Services (which could include content such as photos, location, emoji,
					etc.) to other users of the PitchN Services, we may collect Personal
					Data that includes the content of the message.{" "}
				</li>
				<li>
					<strong>User Activity.</strong> We collect certain Personal Data that
					is generated through your use of the PitchN Services. This Personal
					Data includes information about whose user profiles you have viewed,
					how you’ve interacted with the App, and how you communicate with other
					users.{" "}
				</li>
			</ul>
			<h2>
				PERSONAL DATA WE RECEIVE FROM YOUR DEVICE WHEN YOU USE PITCHN SERVICES{" "}
			</h2>
			<ul>
				<li>
					<strong>Hardware and Software Information.</strong> We collect certain
					hardware and software information about you and your device such as
					device make, device model, carrier, data connection type, browser
					type, operating system, IP address, domain name, date/time stamp for
					your visit, app crashes, advertising IDs (e.g. Google’s ADID and
					Apple’s IDFA) which can be reset by going to your device’s setting).
					All of the foregoing collectively known as “Hardware and Software
					Information”.{" "}
				</li>
				<li>
					<strong>Cookies, Log Files, and Other Tracking Technologies.</strong>
					We use cookies and URL information to gather information regarding the
					date and time of your visit and your searches. If you choose to
					disable cookies, some features of the PitchN Services may not work as
					expected. For more Information regarding cookies and other tracking
					technologies, please see <a href="#cookie-information">
						Cookie Information.
					</a>{" "}
				</li>
			</ul>
			<h2>PERSONAL DATA WE RECEIVE FROM THIRD PARTIES</h2>
			<ul>
				<li>
					<strong>Third-Party Platforms.</strong>If you use or connect to the
					PitchN Services or download the PitchN Services or login to the App
					through a third-party platform, such as Facebook, Google, or
					Instagram, you allow us to access and/or collect certain
					information(e.g., date of birth, account identifier, etc.) from your
					third-party profile/account (“Third-Party Data”). We may also access
					information contained in tracking technology placed on your device by
					the third-party platform. Remember, when you elect to create a PitchN
					App account via a third-party, you are also giving them Personal Data,
					so we recommend you read their privacy policies which control how they
					use your information and may also provide additional data management
					tools you can use.
				</li>
				<li>
					<strong>Google Services - Google Maps API.</strong>Our platform uses
					the Google Maps API in our Services to provide the distance between
					users based on geolocation data. By using the Services you agree to
					Google’s Privacy Policy located at{" "}
					<a href="http://www.google.com/policies/privacy" target="_blank">
						http://www.google.com/policies/privacy/
					</a>
					.
				</li>
				<li>
					<strong>Here Services - Here Maps API.</strong>Our platform uses the
					Here Maps API in our Services to provide the distance between users
					based on geolocation data. By using the Services you agree to Google’s
					Privacy Policy located at{" "}
					<a href="https://legal.here.com/en-gb/privacy" target="_blank">
						https://legal.here.com/en-gb/privacy
					</a>
					.
				</li>
			</ul>
			<h2 id="how-we-share-data">HOW WE SHARE YOUR PERSONAL DATA. </h2>
			<ul>
				<li>
					<strong>Distance Information.</strong>The PitchN App is designed to
					allow users to share Distance Information. Distance Information is
					public and indicates to other users of the PitchN App the distance you
					are in miles or kilometers from them. Your Distance Information will
					be displayed to all PitchN App users within a radius of up to 100
					miles (~161 km) from your login location. Sophisticated users who use
					the PitchN App in an unauthorized manner, or other users who change
					their location while you remain in the same location, may use Distance
					Information to determine your exact location and may be able to
					determine your identity. You may choose to hide your Distance
					Information; however, the PitchN App will continue to sort and display
					your profile based on your relative distance from other users.
					Accordingly, even if you choose to hide your Distance Information,
					others may nevertheless be able to determine your Location. You can
					disable location services on an iPhone by going to Settings, Privacy,
					Location Services, PitchN and on Android, by going to Settings,
					Location, PitchN, Permissions, Location.
				</li>
				<li>
					<strong>We share your Personal Data.</strong>We share your Personal
					Data with Pitchn Inc.’s product partner, R2 Consulting and its Indian
					Subsidiary Company R2Cloud and its Employees. We share your Personal
					Data with R2 Consulting, R2Cloud, and its employees, (collectively
					“R2”) to: provide you with the PitchN Services; conduct quality
					assurance testing; monitor activity on the PitchN Services; provide
					technical support; and/or to provide other services to Pitchn Inc. and
					The App. Except as otherwise stated in this Privacy Policy, R2 is
					required by contract not to use your Personal Data other than in
					connection with the services requested by the PitchN App and the
					PitchN Site.{" "}
				</li>
				<li>
					<strong>Third-Party Service Providers.</strong>
					We share your Personal Data with third-party service providers to:
					provide you with the PitchN Services; conduct quality assurance
					testing; monitor activity on the PitchN Services; provide technical
					support; and/or to provide other services to Pitchn Inc.. Except as
					otherwise stated in this Privacy Policy, these third-party service
					providers are required not to use your Personal Data other than in
					connection with the services requested by Pitchn Inc. The only service
					provider with access to database information is Amazon Web Services
					(AWS), our data infrastructure provider.
				</li>
				<li>
					<strong>Legal Obligations.</strong>PITCHN may preserve or disclose
					Personal Data in response to a lawful legal request, including:
					subpoenas, warrants, or court orders; in connection with any legal
					process; to comply with relevant laws; to establish or exercise our
					rights to defend against legal claims; if we believe it is necessary
					to investigate, prevent, or take action regarding illegal activities,
					suspected fraud, safety of person or property, or a violation of our
					policies; or as otherwise required by law.{" "}
				</li>
			</ul>
			<h2>YOU SHARE YOUR PERSONAL DATA IN THE FOLLOWING SITUATIONS:</h2>
			<ul>
				<li>
					<strong>Community Profiles.</strong> The PitchN App is a social
					help-oriented platform that is designed to allow users to connect and
					share information to combat the ongoing COVID-19 crisis. When you use
					the PitchN Services, your Community Profile Information is public, and
					other users of the PitchN App can see your Profile Information and
					other Personal Data (including sensitive personal data) posted by you.
					For example, other users can see published category-based help lists
					when using our Help feature. Users may also be able to see published
					goods and supply items when using the Pantry feature, which is a
					community marketplace for sharing. Users are also able to see you
					listed as being within their vicinity (within a 100-mile radius). Do
					not use the PitchN Services if you want to keep any Profile
					Information private. Please remember that sophisticated users who use
					the PitchN App in an unauthorized manner may be able to obtain Profile
					Information and may be able to determine your identity.{" "}
				</li>
				<li>
					<strong>Messages.</strong>You may choose to send messages, including
					photos, location, or emoji to other users. If you send messages, you
					will be providing PITCHN with your Personal Data as well as the user
					who views your message. Please don't send messages or share content
					that you wouldn't want someone to save or share as users may save
					content or copy it outside the PitchN Services. Chat messages are
					stored locally on devices. Therefore, if you choose to delete your
					account, messages you sent to another user may still be available on
					that user’s device. Do not share your Account Credentials or payment
					information via in-app messaging.
				</li>
				<li>
					<strong>Social Features.</strong>The PitchN Services include features
					that are designed to permit interactions that you initiate between the
					PitchN Services and third-party websites or services, including
					third-party social networks (collectively "Social Features"). For
					example, you may be able to "like" or "share" content from the PitchN
					Services on other websites or services, such as Facebook, Twitter,
					Instagram, YouTube and LinkedIn. If you use Social Features on the
					PitchN Services, both Pitchn Inc. and the third-party services that
					operate those Social Features may have access to certain Personal Data
					about you and your use of both the PitchN Services and theirs. The
					Personal Data collected and stored by third parties remains subject to
					those third parties' privacy practices, including whether the third
					parties continue to share Personal Data with us.{" "}
				</li>
			</ul>
			<br />
			<strong>
				You agree and consent that the information and data outlined above may
				be shared with third parties as described throughout this document.{" "}
			</strong>
			<h2 id="data-retention">DATA RETENTION.</h2>
			We retain your Personal Data for no longer than is necessary to fulfill
			the purposes for which the information was collected or as otherwise
			permitted or required by applicable law or pursuant to our Terms of Use.
			<br />
			If you delete your account your Profile Information and Location
			Information associated with your account will be removed from our systems
			within 90 days, unless retention is required for a legal purpose.
			Messaging images, user activity and other Personal Data outlined above is
			deleted within 120 days. With respect to accounts that have been banned
			for violations of the PitchN Terms of Use, Pitchn Inc. retains the data on
			your social media login and ID, an PITCHN-generated device identifier, and
			limited log information associated with the reason for the ban. In some
			circumstances, Pitchn Inc. may retain chat messages used for reporting
			violative behavior resulting in an account’s ban. Pitchn Inc. may also
			retain your Personal Data if required to do so pursuant to a valid legal
			request. For more information, please see{" "}
			<a href="">Third Party Disclosures</a>.
			<h2 id="how-we-protect-data">HOW WE PROTECT YOUR PERSONAL DATA. </h2>
			At PitchN Inc., we take the privacy and security of your Personal Data
			seriously. We actively work to monitor the integrity and safety of the
			PitchN Services. We have strict policies in place to help protect Personal
			Data. We consistently strive to improve our defenses in the ongoing battle
			against bad actors. As such, we do not disclose any specific security
			tools or techniques we use so that we can better protect your Personal
			Data as we do not want to unwittingly educate those with malicious intent.
			That said, no method of transmission over the Internet, or method of
			electronic storage, is fully secure, and Pitchn Inc. cannot guarantee the
			security of your Personal Data. In the event that we are required by law
			to inform you of any unauthorized access to your Personal Data, we may
			notify you electronically or in writing in accordance with applicable law.
			<h2 id="your-choices">YOUR CHOICES. </h2>
			You can make the following choices regarding your Personal Data:
			<ul>
				<li>
					<strong>Community Profile Information.</strong>You choose to provide
					us Personal Data about yourself for your public PitchN community
					profile. You can review and change your Community Profile Information
					at any time by accessing the “Edit Profile” section of the App.{" "}
				</li>
				<li>
					<strong>Third-Party Tracking. </strong>
					<ul>
						<li>
							You may opt-out of Google Analytics' collection and use of your
							Personal Data by following the instructions{" "}
							<a href="https://tools.google.com/dlpage/gaoptout">here</a> to
							download an opt-out browser add-on.
						</li>
						<li>
							You may opt-out of Firebase’s collection and use of your Personal
							Data by following the instructions to programmatically disable
							data collection from that use the Firebase SDK:{" "}
							<a href="https://firebase.google.com/docs/analytics/configure-data-collection">
								how to instructions
							</a>
						</li>
						<li>
							You may programmatically disable data collection on the web from
							<ul>
								<li>
									App + Web properties with gtag.js:{" "}
									<a href="https://developers.google.com/analytics/devguides/collection/app-web/disable-analytics">
										instructions
									</a>
								</li>
								<li>
									Web properties with gtag.js:{" "}
									<a href="https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out">
										instructions
									</a>
								</li>
								<li>
									Web properties with analytics.js:{" "}
									<a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out">
										instructions
									</a>
								</li>
								<li>
									Web properties with ga.js:{" "}
									<a href="https://developers.google.com/analytics/devguides/collection/gajs/#disable">
										instructions
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</li>
			</ul>
			<h2 id="your-rights">YOUR RIGHTS.</h2>
			Some jurisdictions may give you the right to request that we:
			<ul>
				<li>
					Provide you with information about our processing of your Personal
					Data and give you access to your Personal Data.{" "}
				</li>
				<li>Update or correct inaccuracies in your Personal Data. </li>
				<li>Delete your Personal Data. </li>
				<li>
					Transfer a machine-readable copy of your Personal Data to you or a
					third party of your choice.{" "}
				</li>
				<li>Restrict the processing of your Personal Data. </li>
				<li>
					Withdraw consent that you have previously given us. You may make this
					request at any time. Revocation of your consent will only apply to
					future processing activities and will not apply retroactively.{" "}
				</li>
				<li>
					Where your jurisdiction’s law allows, object reliance on our
					legitimate interests as the basis for processing of your Personal
					Data.{" "}
				</li>
			</ul>
			While we honor the above requests if required to do so by law, there are
			situations in which we may not be able to agree to a particular request.
			For example, it is not possible for us to delete your Personal Data if we
			are required by law to keep it (e.g., for tax and accounting purposes), or
			if we need it to perform a contract with you. Similarly, access to your
			Personal Data may be refused if making the information available would
			reveal Personal Data about another person or if we are legally prevented
			from disclosing such information. If we decline your request, we will tell
			you why, subject to legal restrictions.
			<br />
			<br />
			Please note that due to Pitchn Inc.’s retention practices, some historical
			data may not be available as it is no longer held in Pitchn Inc.’s
			systems. Please see "Data Retention" above. You can submit a request by
			email to help@pitchncares.org or our postal address provided below. Where
			necessary, we may request specific information from you to verify your
			identity prior to processing your request. If you invoke your rights as
			listed in this section, contact our Customer Support using the
			help@pitchncares.org email to help fulfill your requests to exercise your
			rights under this document.
			<br />
			<br />
			If you would like to submit a complaint about our use of your Personal
			Data or our response to your requests regarding your Personal Data, you
			may contact us at help@pitchncares.org
			<h2 id="for-california-residents">FOR CALIFORNIA RESIDENTS. </h2>
			Except as expressly identified below, we do not disclose a user’s personal
			data to any third-party for such third-party’s direct marketing purposes.
			Thus, under California Civil Code Sections 1798.80-1798.84, Owner has no
			obligation to a California resident to provide further information
			regarding Owner’s use of the California resident’s personal data. Any
			inquiries regarding the Terms of Use or, specifically, this Privacy Policy
			can be directed to the contact identified below.
			<h2>FOR UTAH RESIDENTS</h2>
			Except as expressly identified below, we do not disclose a user’s personal
			data to any third-party for such third-party’s direct marketing purposes.
			<h2 id="for-eea-uk-data-subjects">FOR EEA/U.K. DATA SUBJECTS. </h2>
			For users subject to the data privacy laws of the EEA and the U.K. the
			table below provides an overview of the legal basis on which we process
			your data. Where the legal basis is your consent, you can withdraw your
			consent at any time (typically by controlling what data you provide in
			your PitchN Community Profile). Where the legal basis is legitimate
			interests, you have a right to object to our use of your data. We explain
			in the relevant sections in this Privacy Policy how you can withdraw
			consent or opt-out of certain data uses. Note that sometimes we ask your
			permission concerning our use of data in order to give you control over
			preferences, but the legal basis is not "consent."
			<br />
			<br />
			<table>
				<thead>
					<tr>
						<th>Processing purpose</th>
						<th>Personal Data Includes</th>
						<th>Lawful Basis</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							1. Provide you with the services and products associated with a
							PitchN App account, including without limitation creating and
							managing your PitchN App account and PitchN App user profile.{" "}
						</td>
						<td>
							Account Credentials; Profile Information; Location and Distance
							Information; Hardware and Software Information; and Cookies, Log
							Files and Other Tracking Technologies. <br />
							Additional Personal Data we receive about you, including:
							Third-Party Data and Consent Preferences.
						</td>
						<td>
							Performance of contract <br />
							Manifestly made public by the data subject (with regard to
							sensitive information){" "}
						</td>
					</tr>
					<tr>
						<td>
							2. Display your profile and your PitchN App activity to other
							users and enable you to see nearby user profiles and published
							help lists.
						</td>
						<td>
							Location and Distance Information, Profile Information; User
							Activity; Cookies, Log Files and Other Tracking Technologies.{" "}
						</td>
						<td>
							Performance of contract <br />
							Manifestly made public by the data subject (with regard to
							sensitive information){" "}
						</td>
					</tr>
					<tr>
						<td>
							3. Facilitate messages between users of the PitchN Services, which
							may include photos (including explicit photos), location
							information, and address information
						</td>
						<td>
							Hardware: Software Information; Location and Distance Information;
							Messages; Photos; User Activity.{" "}
						</td>
						<td>
							Performance of contract <br />
							Consent (with regard to sensitive information){" "}
						</td>
					</tr>
					<tr>
						<td>
							4. Communicate with you through the App or Site to provide
							updates, service-related notifications, news, and promotions.{" "}
						</td>
						<td>
							Profile Information; Hardware and Software Information; Location
							and Distance Information; Cookies, Log Files and Other Tracking
							Technologies; Feedback and Support; Mailing List; Partner
							Promotions. <br />
							Additional Personal Data that we receive about you, including:
							Receipt Information.
						</td>
						<td>
							Performance of contract <br />
							Legitimate interest of PitchN Services to discuss its products and
							services (in relation to promotions){" "}
						</td>
					</tr>
					<tr>
						<td>
							5. Respond to user inquiries and complaints and to provide you
							customer support.{" "}
						</td>
						<td>
							Account Credentials; Hardware and Software Information; Profile
							Information; Location and Distance Information; Messages; Photos
							and Videos; User Activity; Cookies, Log Files and Other Tracking
							Technologies; Feedback and Support; Surveys.
							<br />
							Additional Personal Data that we received about you, including:
							Receipt Information.
						</td>
						<td>
							Performance of contract <br />
							Legitimate interests – it is in our legitimate interests to
							respond to your inquiries in order to provide you with the
							assistance you expect when raising a support query.{" "}
						</td>
					</tr>
					<tr>
						<td>
							6. Develop, improve, and deliver PitchN Services and products.{" "}
						</td>
						<td>
							Account Credentials; Profile Information; Hardware and Software
							Information; User Activity; Cookies, Log Files and Other Tracking
							Technologies; Feedback and Support; Surveys.
							<br />
							Additional Personal Data that we receive about you, including:
							Receipt Information; User Activity.
						</td>
						<td>
							Legitimate interests– it is in our legitimate interests to
							understand the ways in which our users engage with the PitchN
							Services and other third-party tools/applications so that we can
							further protect PitchN App users, improve the current PitchN
							Services as well as develop new services and products for future
							use.{" "}
						</td>
					</tr>
					<tr>
						<td>7. Conduct Partner promotions. </td>
						<td>
							Profile Information; Authentication Information; Location and
							Distance Information; Partner Promotions.{" "}
						</td>
						<td>
							Legitimate Interests – it is in our legitimate interests to
							provide you with partner promotions in which you may be
							interested.{" "}
						</td>
					</tr>
					<tr>
						<td>
							8. Access and collect your Personal Data from third-party
							platforms.{" "}
						</td>
						<td>
							Third-Party Platform Data; Cookies, Log Files and Other Tracking
							Technologies. Additional Personal Data we receive about you,
							including: Third-Party Platform Data, Hardware and Software
							Information, Receipt Information, and Consent Preferences{" "}
						</td>
						<td>Performance of contract</td>
					</tr>
					<tr>
						<td>
							9. Share your Personal Data with our service providers in order to
							provide you with the PitchN Services.{" "}
						</td>
						<td>
							Hardware and Software Information; Profile Information; Location
							and Distance Information; Messages; Photos; Cookies, Log Files and
							Other Tracking Technologies.{" "}
						</td>
						<td>Performance of contract </td>
					</tr>
					<tr>
						<td>
							9. Share your Personal Data with our service providers in order to
							provide you with the PitchN Services.{" "}
						</td>
						<td>
							Hardware and Software Information; Profile Information; Location
							and Distance Information; Messages; Photos; Cookies, Log Files and
							Other Tracking Technologies.{" "}
						</td>
						<td>Performance of contract </td>
					</tr>
					<tr>
						<td>
							10. Share your Personal Data with analytics partners to improve
							our services, marketing, user relationships and experiences.{" "}
						</td>
						<td>
							Hardware and Software Information; Profile Information; Location
							and Distance Information; Cookies, Log Files and Other Tracking
							Technologies. <br />
							Additional Personal Data we receive about you, including: Consent
							Preferences.
						</td>
						<td>
							Consent (in those cases where required by law) <br />
							Legitimate interests– it is in our legitimate interests to analyze
							the way in which users are accessing and using the PitchN Services
							so that we can further improve them.
						</td>
					</tr>
					<tr>
						<td>
							11. Provide safety and security features (authentication,
							anti-spam, fraud protection and quality and abuse prevention).{" "}
						</td>
						<td>
							Account Credentials; Hardware and Software Information; Profile
							Information; Location and Distance Information; Messages; Photos;
							User Activity; Cookies, Log Files and Other Tracking Technologies.
							<br />
							Additional Personal Data that we receive about you, including:
							Receipt Information and User Activity.{" "}
						</td>
						<td>
							Legitimate interests – it is in our legitimate interests to ensure
							that PitchN accounts are not fraudulent in order to safeguard
							users and provide an authentic and safe platform. <br />
							Protect the vital interests of data subjects or other natural
							persons.{" "}
						</td>
					</tr>
					<tr>
						<td>12. Comply with valid legal requests. </td>
						<td>
							Account Credentials; Hardware and Software Information; Profile
							Information; Location and Distance Information; Messages; Photos;
							User Activity; Cookies, Log Files and Other Tracking Technologies.
							<br />
							Additional Personal Data that we receive about you, including:
							Receipt Information.{" "}
						</td>
						<td>
							Comply with legal obligations (in response to requests from EU
							member states) <br />
							The legitimate interest of Pitchn Inc. to comply with the laws to
							which it is subject outside of the EEA
						</td>
					</tr>
					<tr>
						<td>13. Address legal issues and disputes. </td>
						<td>
							Account Credentials; Hardware and Software; Information; Profile
							Information; Location and Distance Information; Messages; Photos
							and Videos; User Activity; Information and Cookies, Log Files and
							Other Tracking Technologies.
							<br />
							Additional Personal Data we receive about you, including: Receipt
							Information.{" "}
						</td>
						<td>
							Comply with legal obligations (if required by an EU member state){" "}
							<br />
							The legitimate interest of Pitchn Inc. to exercise or defend legal
							claims <br />
							Establishing, exercising, or defending legal claims{" "}
						</td>
					</tr>
					<tr>
						<td>
							14. Investigate, prevent or respond to suspected illegal
							activities or fraud.{" "}
						</td>
						<td>
							Account Credentials; Hardware and Software Information, Profile
							Information; Location and Distance Information; Messages; Photos;
							User Activity; Cookies, Log Files and Other Tracking Technologies.
							<br />
							Additional Personal Data that we receive about you, including:
							Receipt Information and User Activity.{" "}
						</td>
						<td>
							Legitimate interests - it is in our legitimate interests to
							prevent and respond to unauthorized behavior within the PitchN
							Services and to maintain the safety and authenticity of the PitchN
							Services.{" "}
						</td>
					</tr>
					<tr>
						<td>
							15. Administer and protect our services (troubleshooting, data
							analysis, testing, system maintenance, support, reporting and
							hosting of data).{" "}
						</td>
						<td>
							Account Credentials; Hardware and Software Information; Profile
							Information; Location and Distance Information; Messages; Photos
							and Videos; User Activity; Cookies, Log Files and Other Tracking
							Technologies.
							<br />
							Additional Personal Data that we receive about you, including:
							Payment Information and User Activity.{" "}
						</td>
						<td>
							Legitimate interests – it is in our legitimate interests to ensure
							the stability of the PitchN Services and are able to use
							analytical data to troubleshoot problems.{" "}
						</td>
					</tr>
					<tr>
						<td>
							16. Access your camera, photo roll, and microphone to allow you to
							include photos in your Community Profile and photos, audio, and
							video messages in your private and group messages.{" "}
						</td>
						<td>Profile Information; Messages; Photos and Videos. </td>
						<td>Consent</td>
					</tr>
					<tr>
						<td>
							17. Facilitate the sharing of location distance between you and
							other users based on location upon login.{" "}
						</td>
						<td>
							Location and Distance Information; Profile Information; Messages.
						</td>
						<td>Consent</td>
					</tr>
					<tr>
						<td>
							18. Provide you with services and products associated with PitchN
							App in-app messaging, including syncing your PitchN App account
							with your web client.{" "}
						</td>
						<td>
							Location and Distance Information; Profile Information; Messages;
							Photos and Videos; Cookies, Log Files and Other Tracking
							Technologies; Payment Information.{" "}
						</td>
						<td>
							Consent
							<br />
							Performance of contract (related to the in-app Messaging service){" "}
						</td>
					</tr>
					<tr>
						<td>
							19. Create and/or administer surveys and conduct research of
							users’ behavior to improve the PitchN Services.{" "}
						</td>
						<td>
							Profile Information; Location and Distance Information; Hardware
							and Software Information; User Activity; Cookies, Log Files and
							Other Tracking Technologies; Feedback and Support; Surveys;
							Payment Information.{" "}
						</td>
						<td>Consent</td>
					</tr>
				</tbody>
			</table>
			<br />
			For General Data Protection Regulation (EU) 2016/679 (“GDPR”)-related
			inquiries, you may contact:{" "}
			<a href="mailto:help@Pitchncares.org" rel="nofollow">
				help@Pitchncares.org
			</a>
			. <br />
			<br />
			EEA individuals also have the right to lodge a complaint with a
			supervisory authority in the EEA Country where they reside or work, or
			where the alleged infringement of the General Data Protection Regulation
			(EU) 2016/679 (“GDPR”) took place. You can find information about your
			data protection regulator{" "}
			<a
				target="_blank"
				rel="noopener noreferrer"
				href="https://edpb.europa.eu/about-edpb/board/members_en"
			>
				here
			</a>
			. Swiss individuals also have the right to lodge a complaint with the
			Swiss Federal Data Protection and Information Commissioner. For complaints
			from EEA data subjects or Swiss individuals that cannot be resolved with
			PITCHN directly, PITCHN will cooperate with EEA and Swiss authorities and
			will implement the advice they provide with respect to such unresolved
			complaints.
			<h2 id="cookie-information">COOKIE INFORMATION. </h2>
			<strong>What are cookies:</strong> <br />
			<br />
			“Cookies” are small text files placed on your device or browser when you
			visit a website or otherwise interact with an online service. Cookies
			collect and store information, which is sent back to our servers or those
			of third parties. To learn more about cookies, we encourage you to visit{" "}
			<a
				target="_blank"
				rel="noopener noreferrer"
				href="https://www.allaboutcookies.org"
			>
				www.allaboutcookies.org
			</a>
			.
			<ul>
				<li>
					First-party and third-party cookies. PitchN websites utilize
					first-party and third-party cookies. First-party cookies are cookies
					placed on your device directly by us (e.g., to adapt our website to
					your browser’s language preferences and to better understand your use
					of the PitchN Services). Third-party cookies are placed on your device
					by our partners and service providers (e.g., to gather performance
					data or to enable you log on using a third-party social media
					platform).{" "}
				</li>
				<li>
					Session and persistent cookies. All cookies have an expiration date.
					There are session cookies and persistent cookies. We use session
					cookies for many reasons, including to learn more about your use of
					the PitchN Services during a single browsing session so we can
					continue to ensure the PitchN Services are effective and efficient. We
					typically use persistent cookies to help expedite your use of the
					PitchN Services and for performance evaluation purposes.{" "}
				</li>
			</ul>
			<br />
			<strong>How does PitchN use cookies?</strong>
			<br />
			<br />
			As described in greater detail below, we may use cookies and other
			tracking technologies for the following purposes:
			<ul>
				<li>
					To collect statistical data on our users’ interactions with the PitchN
					Services;{" "}
				</li>
				<li>
					To conduct essential functions, such as identifying trusted web
					traffic;{" "}
				</li>
				<li>To integrate with third-party social media platforms; </li>
				<li>
					To track your behaviors and browsing activities over time across
					multiple websites or other platforms; and{" "}
				</li>
				<li>
					To serve you with interest-based or targeted advertising on other
					websites.{" "}
				</li>
			</ul>
			We may link the information collected by cookies with other information we
			obtain from you pursuant to this Privacy Policy and may use the combined
			information in ways as outlined herein. Similarly, the third parties who
			serve cookies within the PitchN Services may link the data they obtain
			through their cookies within the PitchN Services to other information they
			may have collected about you. Anytime you interact with or provide your
			Personal Data to a third-party site, we encourage you to consult the
			site’s privacy policy.
			<br />
			<br />
			<strong>How can you control cookies?</strong>
			<br />
			<br />
			There are several options available for you to use in order for you to
			manage cookies and tracking technologies while using the PitchN Services.
			<ul>
				<li>
					General Opt-Out. Most web browsers accept cookies automatically. You
					also may be able to configure your browser settings to use the PitchN
					Services without some cookie functionality. You can delete cookies
					manually or set your browser to automatically delete cookies on a
					pre-determined schedule. For example, in the Internet Explorer menu
					bar, select: Tools -> Internet Options -> Browsing History -> Delete
					to view manual and automatic options.{" "}
				</li>
				<li>
					To learn about how to manage cookies on popular browsers, please
					visit:{" "}
					<a
						href="https://support.apple.com/en-gb/safari"
						target="_blank"
						rel="noopener noreferrer"
					>
						Apple Safari
					</a>
					,{" "}
					<a
						href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
						target="_blank"
						rel="noopener noreferrer"
					>
						Google Chrome
					</a>
					,{" "}
					<a
						href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy"
						target="_blank"
						rel="noopener noreferrer"
					>
						Microsoft Edge
					</a>
					,{" "}
					<a
						href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
						target="_blank"
						rel="noopener noreferrer"
					>
						Microsoft Internet Explorer
					</a>
					,{" "}
					<a
						href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
						target="_blank"
						rel="noopener noreferrer"
					>
						Mozilla Firefox
					</a>
					,{" "}
					<a
						href="https://brave.com/faq"
						target="_blank"
						rel="noopener noreferrer"
					>
						Brave
					</a>
					,{" "}
					<a
						href="https://duckduckgo.com/about"
						target="_blank"
						rel="noopener noreferrer"
					>
						DuckDuckGo
					</a>
					.{" "}
				</li>
				<li>
					You may also be able to reset device identifiers through settings on
					your mobile device. The procedure for managing device
					identifiers(e.g., IDFA) is different for each device. You can check
					the specific steps in the help or settings menu of your particular
					device.{" "}
				</li>
			</ul>
			<h2 id="additional-information">ADDITIONAL INFORMATION. </h2>
			The following additional information relates to our privacy practices:
			<ul>
				<li>
					<strong>Permissible Age.</strong> The PitchN Services are intended
					only for users eighteen (18) years of age and older (or twenty-one
					(21) years of age in places where eighteen (18) years is not the age
					of majority). We do not intentionally gather Personal Data about
					visitors who are under the age of majority. If you are aware that a
					child or minor has submitted{" "}
					<strong>Personal Data on the PitchN Services</strong>, please contact
					us using the information provided below, or using the in-app reporting
					tool, so that we can take appropriate action.{" "}
				</li>
				<li>
					<strong>Commitment to Children’s Privacy Protection.</strong> In
					compliance with the Children's Online Privacy Protection Act, 15
					U.S.C., §§ 6501-06 and 16 C.F.R., §§ 312.1-312.12, neither the App nor
					the Site collects information from children under 13 years of age, and
					we do not intentionally collect information from persons under
					eighteen (18) years of age. Use of the Site is limited to users as
					detailed above under (Permissible Age).{" "}
				</li>
				<li>
					<strong>Do Not Track.</strong> Some internet browsers incorporate a
					“Do Not Track” feature that signals to websites you visit that you do
					not want to have your online activity tracked. Given that there is not
					a uniform way that browsers communicate the “Do Not Track” signal, the
					Site does not currently interpret, respond to or alter its practices
					when it receives “Do Not Track” signals.{" "}
				</li>
				<li>
					<strong>Third-Party Applications/Websites.</strong> We have no control
					over, do not review, and cannot be responsible for the privacy and
					data handling practices of other websites or applications. Once you
					click on a link to another website or third-party application, the
					terms of this Privacy and Cookie Policy do not apply.{" "}
				</li>
				<li>
					<strong>Changes To This Privacy and Cookie Policy.</strong> If we make
					a material change to this Privacy and Cookie Policy we will, at a
					minimum, attempt to notify you of that change either by sending an
					e-mail to the last e-mail address that you provided to us (if any) or
					by notifying you when you log into the PitchN Services.{" "}
				</li>
				<li>
					<strong>Translations.</strong> In the event there is a discrepancy
					between this English language version and any translations of this
					Policy, the English version shall prevail unless otherwise required
					under applicable law.{" "}
				</li>
				<li>
					<strong>Contact Information.</strong> PitchN welcomes your comments or
					questions regarding this Privacy and Cookie Policy. Please contact us
					by email at{" "}
					<a href="mailto:help@Pitchncares.org" rel="nofollow">
						help@Pitchncares.org
					</a>{" "}
					or by postal mail at the following address:{" "}
				</li>
			</ul>
            Pitchn Inc. <br />
            Attn: Data Protection <br />
            4433 Scarsdale Pl<br />
            Boulder, CO 80301<br />
            Effective Date: September 17, 2020 or the date of user acceptance. 
		</div>
	</div>
);

export default PrivacyPolicy;
