import React from 'react';
import '../PreOrderSteps/styles.scss';

const PreOrderSteps = () => {
    return (
        <div className="detailed-instructions">
            <div>
                <div className="title">
                Steps to Give help
                </div>
                <div className="step-item">
                Giving help has never been easier! Volunteer with a click of a button. Run 
                errands and make a difference.
                </div>
                <div className="step-item">
                <span className="step-label">Step 1.</span> Download PitchN App and Login. 
                </div>
                <div className="step-item">
                <span className="step-label">Step 2.</span> Agree to Volunteer
                </div>
                <div className="step-item">
                <span className="step-label">Step 3.</span>Search Help Requests in your local community (you set the 
                    radius; default is 25 miles)
                </div>
                <div className="step-item">
                    <span className="step-label">Step 4.</span> Run an errand on behalf of someone in true need
                </div>
                <div className="step-item" style={{marginLeft: '20px'}}>
                    <span className="special-note">a. Shop for groceries, medicine, or critical supplies</span>
                </div>
                <div className="step-item" style={{marginLeft: '20px'}}>
                    <span className="special-note"> b. Pick-up Pre-ordered items like food from a restaurant or 
                    medication from a pharmacy</span>
                </div>
                <div className="step-item">
                    <span className="step-label">Step 5.</span> Use messaging to make seamless arrangements
                </div>
                <div className="step-item">
                    <span className="step-label">Step 6.</span> Deliver Items to the person that requested your help
                </div>
                <div className="step-item">
                    <span className="step-label">Step 7.</span> Upload a digital receipt
                </div>
                <div className="step-item">
                    <span className="step-label">Step 8.</span> Receive full reimbursement (if applicable). 
                </div>
                <div className="step-item">
                    <span className="special-note">Please see Reimbursement for more detailed instructions.</span>
                </div>
            </div>
            <div className="give-help-remarks">
                <div className="step-item">
                    <span className="special-note">*Please see REIMBURSEMENT section for more detail.</span> 
                </div>
                <div className="step-item">
                    <span className="special-note">(ii)Tip on reimbursement: </span> 
                    <span className="step-label">If you use PitchN to Give help (volunteer to help someone) </span> 
                </div>
                <div className="step-item">
                    <span className="step-label">1) running non-prepaid errands and/or </span> 
                </div>
                <div className="step-item">
                    <span className="step-label">2) picking up non-prepaid items</span> 
                </div>
                <div className="step-item">
                    <span className="step-label">A volunteer is entitled to receive full reimbursement 
                    (including applicable taxes) from the person that you 
                    helped if you pay for items on behalf of that individual.
                     </span> 
                </div>
                <div className="step-item">
                    <span className="step-label"> PitchN is not responsible for reimbursement. You must 
                    coordinate this with the person that you help to receive 
                    your reimbursement once you have delivered the 
                    requested items and uploaded a valid digital receipt. 
                    </span> 
                </div>
                <div className="step-item">
                    <span className="step-label">- Tips and gratuities are not included as this service 
                    is done out of the goodness of each PitchN volunteer’s 
                    heart. 
                    </span> 
                </div>
                <div className="step-item">
                    <span className="step-label">- Reimbursement is not required if the Pre-Order 
                    Pick-up was prepaid.</span> 
                </div>
                <div className="step-item">
                    <span className="step-label">- Reimbursement is not associated with the use of the
                    Pantry feature. The Pantry feature enables the sharing 
                    of critically needed items. </span> 
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}

export default PreOrderSteps;