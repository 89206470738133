import React, { Suspense } from "react";
import TagManager from "react-gtm-module";
import "./styles.scss";
import FeaturesSection from "./FeaturesSection";
import { Parallax } from "react-scroll-parallax";
import VisibilitySensor from 'react-visibility-sensor';
import VideoModal from "./VideoModal";
// const PitchnPages = React.lazy(() => import("./PitchnPages"));
// import PitchnPages from "./PitchnPages";

class HomePage extends React.Component {
    constructor() {
        super();
        this.state = {
            popAnimationClass: ''
        }
    }

	componentDidMount() {
		const data = {
			event: "PageView",
			pageDetails: {
				page: "home",
				pagePath: "/home",
			},
		};
		TagManager.dataLayer(data);
    }
    
    triggerPhonesPop = (isVisible) => {
        if(isVisible) {
            this.setState({
                popAnimationClass: 'phones-pop'
            })
        }
    }

	render() {
		return (
			<div className="homepage">
				<Parallax
					className="background-p"
					y={["-200px", "200px"]}
					tagOuter="figure"
				>
					<img src="./img/pitchn-tilted.svg" alt="" />
				</Parallax>
				<div className="main-container">
					<div className="coming-soon">
						<div className="content">Coming Soon!</div>
					</div>
					<div className="main-title-container">
						<div className="title-section">
							<div className="title-top">
								<span>
									<img
										style={{ margin: "0 5px 5px 0" }}
										src="../img/heart.svg"
										alt=""
									/>
								</span>
								Fight Coronavirus as a Community
							</div>
							<div className="title-middle">
								PitchN:&nbsp;
								<br />
								The Covid-19&nbsp;
								<br />
								Help App
							</div>
							<div className="title-bottom">
								Give & receive help during this&nbsp;
								{/* <br /> */}
								health crisis.
							</div>
						</div>
						<div className="phone-images">
							{/* <img src="./img/pitchn-video.png" alt="" /> */}
                            <VideoModal imgUrl="./img/pitchn-video.png" videoUrl="https://www.youtube.com/embed/fevCYf9Nkyg" />
							<div className="download-buttons">
								<div style={{ marginRight: "20px" }}>
									<img src="./img/apple-store.png" alt="apple-store-download" />
								</div>
								<div>
									<img src="./img/gplay.png" alt="google-play-download" />
								</div>
							</div>
						</div>
					</div>

					<FeaturesSection />
					<VisibilitySensor partialVisibility={true} onChange={this.triggerPhonesPop}>
                        <div className={`pitchn-pages ${this.state.popAnimationClass}`}>
                            <img src="./img/get-help-page.png" alt="" />
                            <img src="./img/volunteer-page.png" alt="" />
                            <img src="./img/pantry-page.png" alt="" />
                        </div>
                    </VisibilitySensor>

					{/* <div className="collaborators">
						<div>With Major Shopping Collaborations</div>
						<div className="shops">
							<div>
								<img src="./img/walmart-logo.svg" alt="" />
							</div>
							<div>
								<img src="./img/kroger-logo.svg" alt="" />
							</div>
							<div>
								and more
								<br /> coming soon
							</div>
						</div>
						<div>With Multiple Reimbursement Options</div>
						<div className="shops">
							<div>
								<img src="./img/paypal-logo.png" alt="" />
							</div>
							<div>
								<img src="./img/venmo-logo.svg" alt="" />
							</div>
							<div>
								<img src="./img/paytm-logo.svg" alt="" />
							</div>
						</div>
						<div className="shops">
							<div className="shop-payment">
								<img src="./img/checks.png" alt="" />
								<div style={{ fontWeight: 500 }}>Checks</div>
							</div>
							<div className="shop-payment">
								<img src="./img/cash.png" alt="" />
								<div style={{ fontWeight: 500 }}>Cash</div>
							</div>
						</div>
					</div> */}

					<div className="pitchn-download">
						<div className="download-text">
							<div className="thanks">Thank you!</div>
							<div className="use-pitchn">
								It is truly fantastic that you are using PitchN to give &
								receive help.
							</div>
							<div className="download-buttons">
								<div style={{ marginRight: "20px" }}>
									<img src="./img/apple-store.png" alt="apple-store-download" />
								</div>
								<div>
									<img src="./img/gplay.png" alt="google-play-download" />
								</div>
							</div>
						</div>
						<div className="phone-images">
							<img src="./img/pitchn-app-screens.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default HomePage;
