import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Modal from "react-responsive-modal";
import ContactForm from "./ContactForm";
import WorkingPage from "./WorkingPage";
import GiveHelp from "./WorkingPage/GiveHelp";
import Pantry from "./WorkingPage/Pantry/Pantry";
import HomePage from "./HomePage";
import SafetyPage from "./SafetyPage";
import AmbassadorPage from "./AmbassadorPage";
import ScrollUpButton from "react-scroll-up-button";
import WhyPitchn from "./WhyPitchn";
import Footer from "./Footer";
import PreOrderSteps from "./PreOrderSteps";
import GiveHelpSteps from "./GiveHelpSteps";
import "./App.scss";
import "react-responsive-modal/styles.css";
import AmbassadorFaqPage from "./AmbassadorFaqPage";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import Econsent from "./Econsent";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			showSidebar: false,
			dropdownOpen: false,
		};
	}

	toggleDropdown = () => {
		this.setState((prevState) => ({
			dropdownOpen: !prevState.dropdownOpen,
		}));
	};

	onOpenModal = () => {
		this.setState({ modalOpen: true });
	};

	onCloseModal = () => {
		this.setState({ modalOpen: false });
	};

	toggleSocialSidebar = () => {
		this.setState((prevState) => ({
			showSidebar: !prevState.showSidebar,
		}));
	};

	sidebarOpen = (e) => {
		// console.log("check, ", e.target.checked);
		if (e.target.checked) {
			document.body.style.overflow = "hidden";
			// document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
		} else {
			document.body.style.overflow = "";
			// document.body.style.backgroundColor = '';
		}
	};

	render() {
		const { modalOpen } = this.state;
		return (
			<Router>
				<Switch>
					<Route exact path="/preorder-steps">
						<PreOrderSteps />
					</Route>
					<Route exact path="/give-help-steps">
						<GiveHelpSteps />
					</Route>
					<div className="App">
						<ScrollUpButton ContainerClassName="scroll-up" />
						<div className="title-nav">
							<div className="hamburger-menu" id="menuToggle">
								<input type="checkbox" onClick={this.sidebarOpen} />
								<span></span>
								<span></span>
								<span></span>

								<ul id="menu">
									<a href="/">
										<li>Home</li>
									</a>
									<a href="/get-help">
										<li>Get Help</li>
									</a>
									<a href="/give-help">
										<li>Volunteer</li>
									</a>
									<a href="/pantry">
										<li>Pantry</li>
									</a>
									<a href="/why-pitchn">
										<li>Why PitchN</li>
									</a>
									<a href="/safety">
										<li>Safety</li>
									</a>
									<a href="/ambassadors">
										<li>Ambassadors</li>
									</a>
									<li onClick={this.onOpenModal}>Contact Us</li>
								</ul>
							</div>
							<div
								className="title-name"
								onClick={() => (window.location.href = "/")}
							>
								<img
									style={{ marginRight: "20px" }}
									src="./img/PitchN_logo.svg"
									alt=""
								/>
								PitchN
							</div>
							<div className="nav-links">
								<a
									className="nav-link dropdown-parent"
									onClick={this.toggleDropdown}
								>
									How It Works
									<div
										className="nav-dropdown"
										style={{
											display: this.state.dropdownOpen ? "block" : "none",
										}}
									>
										<a className="nav-dropdown-item" href="/get-help">
											Get Help
										</a>
										<a className="nav-dropdown-item" href="/give-help">
											Volunteer
										</a>
										<a className="nav-dropdown-item" href="/pantry">
											Pantry
										</a>
									</div>
								</a>
								<a className="nav-link" href="/why-pitchn">
									Why PitchN
								</a>
								<a className="nav-link" href="/safety">
									Safety
								</a>
								<a className="nav-link" href="/ambassadors">
									Ambassadors
								</a>
								<a className="nav-link" onClick={this.onOpenModal}>
									Contact Us
								</a>
							</div>
							<Modal
								classNames={{ modal: "modal-container" }}
								open={modalOpen}
								onClose={this.onCloseModal}
								center
							>
								<ContactForm />
							</Modal>
							{/* <div className="social-media">
								<a
									target="_blank"
									href="https://www.facebook.com/PitchN-107026257635275"
								>
									<img src="./images/facebook.svg" alt="" />
								</a>
								<a target="_blank" href="https://www.instagram.com/pitchn_app/">
									<img src="./images/instagram.svg" alt="" />
								</a>
								<a target="_blank" href="https://twitter.com/PitchN2">
									<img src="./images/twitter.svg" alt="" />
								</a>
							</div> */}
						</div>
						{/* <div className="social-sidebar">
							<div className="social-list">
								<div
									className="social-icon"
									onClick={this.toggleSocialSidebar}
								></div>
								<aside
									className={`${
										this.state.showSidebar ? "show-sidebar" : "collapse-sidebar"
									}`}
								>
									<div className="social-icon">
										<a
											target="_blank"
											href="https://www.facebook.com/PitchN-107026257635275"
										>
											<img src="./images/facebook.svg" alt="" />
										</a>
									</div>
									<div className="social-icon">
										<a
											target="_blank"
											href="https://www.instagram.com/pitchn_app/"
										>
											<img src="./images/instagram.svg" alt="" />
										</a>
									</div>
									<div className="social-icon">
										<a target="_blank" href="https://twitter.com/PitchN2">
											<img src="./images/twitter.svg" alt="" />
										</a>
									</div>
									<div className="social-icon">
										<a target="_blank" href="https://pitchnapp.tumblr.com">
											<img src="./images/tumblr.svg" alt="" />
										</a>
									</div>
									<div className="social-icon">
										<a target="_blank" href="https://tiktok.com/@PitchNapp">
											<img src="./images/tiktok.svg" alt="" />
										</a>
									</div>
								</aside>
							</div>
						</div> */}
						<Route exact path="/">
							<HomePage />
						</Route>
						<Route exact path="/get-help">
							<WorkingPage />
						</Route>
						<Route exact path="/give-help">
							<GiveHelp />
						</Route>
						<Route exact path="/pantry">
							<Pantry />
						</Route>
						<Route exact path="/safety">
							<SafetyPage />
						</Route>
						<Route exact path="/why-pitchn">
							<WhyPitchn />
						</Route>
						<Route exact path="/ambassadors">
							<AmbassadorPage />
						</Route>
						<Route exact path="/ambassadors-faq">
							<AmbassadorFaqPage />
						</Route>
						<Route exact path="/privacy-policy">
							<PrivacyPolicy />
						</Route>
						<Route exact path="/terms-of-service">
							<TermsOfService />
						</Route>
						<Route exact path="/econsent">
							<Econsent />
						</Route>
						<Footer openContactForm={this.onOpenModal} />
					</div>
				</Switch>
			</Router>
		);
	}
}

export default App;
