import React from "react";
import { useEffect, useState, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import ScrollSlider from "./ScrollSlider";
import VideoModal from "./VideoModal";

const FeaturesSection = () => {
	const bgText = (scrollPercent) => {
		let text = "";
		if (scrollPercent < 40) {
			text = (
				<div className="bg-text">
					Get
					<br />
					<span style={{ fontSize: "133px" }}>Help</span>
				</div>
			);
		} else if (scrollPercent >= 40 && scrollPercent < 80) {
			text = (
				<div className="bg-text">
					Give
					<br />
					<span style={{ fontSize: "133px" }}>Help</span>
				</div>
			);
		} else {
			text = <div className="bg-text">Pantry</div>;
		}
		return text;
	};
	const calcScrollPercentage = (val) => {
		return (
			(100 * -val) /
			(calcDynamicHeight(objectRef.current.scrollWidth) -
				0.33 * window.innerWidth)
		);
	};
	const scrollToThis = (ref) => {
		const offsetLeft = -ref.current.offsetLeft;
		if (ref.current.id === "block_1") {
			setScrollPercentage(0);
		} else if (ref.current.id === "block_2") {
			setScrollPercentage(55);
		} else {
			setScrollPercentage(95);
		}
		setJumpToNext(true);
		setTranslateX(offsetLeft);
	};
	const calcDynamicHeight = (objectWidth) => {
		const vw = window.innerWidth;
		const vh = window.innerHeight;
		return objectWidth - 0.6 * vw + vh;
	};
	const handleDynamicHeight = (ref, setDynamicHeight) => {
		const objectWidth = ref.current.scrollWidth;
		const dynamicHeight = calcDynamicHeight(objectWidth);
		setDynamicHeight(dynamicHeight);
	};
	const horizontalScroll = (e, ref) => {
		const offsetTop = -ref.current.offsetTop;
		setScrollPercentage(calcScrollPercentage(offsetTop));
		setTranslateX(offsetTop);
	};
	const applyScrollListener = (ref) => {
		window.addEventListener("scroll", (e) => horizontalScroll(e, ref));
	};
	const resetJumpToNext = () => {
		setJumpToNext(false);
	};
	const setVideo1 = (isVisible) => {
		if (isVisible) {
			setArrow1(true);
		}
	};
	const setVideo2 = (isVisible) => {
		if (isVisible) {
			setArrow2(true);
		}
	};
	const setVideo3 = (isVisible) => {
		if (isVisible) {
			setArrow3(true);
		}
	};

	const [dynamicHeight, setDynamicHeight] = useState(null);
	const [translateX, setTranslateX] = useState(0);
	const [scrollPercentage, setScrollPercentage] = useState(0);
	const [jumpToNext, setJumpToNext] = useState(false);
	const [showArrow1, setArrow1] = useState(false);
	const [showArrow2, setArrow2] = useState(false);
	const [showArrow3, setArrow3] = useState(false);

	const containerRef = useRef(null);
	const objectRef = useRef(null);
	const blockOne = useRef(null);
	const blockTwo = useRef(null);
	const blockThree = useRef(null);

	// const [openFirst, setOpenFirst] = useState(false);
	// useEffect(() => { setOpenFirst(openFirst); }, [openFirst]);
	const [openFirst, setOpenFirst] = useState(false);
	const [openSecond, setOpenSecond] = useState(false);
	const [openThird, setOpenThird] = useState(false);

	const resizeHandler = () => {
		handleDynamicHeight(objectRef, setDynamicHeight);
	};

	// const openModal = (popupNumber) => {
	// 	setVideoPopup((prevState) => {
	//         return {...prevState, [popupNumber]: true}
	//     });
	// };

	// const closeModal = (popupNumber) => {
	//     // console.log("triggered", pop upNumber)
	// 	setVideoPopup((prevState) => {
	//         // console.log("triggered", prevState[popupNumber])
	//         return {...prevState, [popupNumber]: false}
	//     });
	// };

	useEffect(() => {
		handleDynamicHeight(objectRef, setDynamicHeight);
		window.addEventListener("resize", resizeHandler);
		applyScrollListener(containerRef, setTranslateX);
		return function cleanupListeners() {
			window.removeEventListener("scroll", horizontalScroll);
			window.removeEventListener("resize", resizeHandler);
		};
	}, []);

	return (
		<div
			className="feature-content-container"
			style={{ height: dynamicHeight }}
		>
			<div className="ticker-container">
				<div className="ticker">
					<div className="ticker-top">
						<span>Stay home.</span>
						<span>Stay Safe.</span>
						<span>Let PitchN Volunteers run errands for you.</span>
					</div>
					<br />
					<div className="ticker-bottom">
						<span>Volunteer or Get Help Now!</span>
						<span>Volunteer or Get Help Now!</span>
						<span>Volunteer or Get Help Now!</span>
					</div>
				</div>
			</div>
			<div className="feature-content" ref={containerRef}>
				<div className="feature-left">
					<div className="title">Features</div>
					<div className="slider-image">
						<div className="scroll-buttons">
							<div
								onClick={() => scrollToThis(blockOne)}
								className="scroll-button-item"
								style={{
									backgroundColor: `${
										scrollPercentage < 40 ? `#66c666` : `#c9c9c9`
									}`,
								}}
							>
								Get Help
								{/* <div className="scroll-button-bg">
                                <img src="./img/p-sideways.svg" alt="" />
                            </div> */}
							</div>
							<br />
							<div
								onClick={() => scrollToThis(blockTwo)}
								className="scroll-button-item"
								style={{
									backgroundColor: `${
										scrollPercentage >= 40 && scrollPercentage < 80
											? `#66c666`
											: `#c9c9c9`
									}`,
								}}
							>
								Volunteer
							</div>
							<br />
							<div
								onClick={() => scrollToThis(blockThree)}
								className="scroll-button-item"
								style={{
									backgroundColor: `${
										scrollPercentage >= 80 ? `#66c666` : `#c9c9c9`
									}`,
								}}
							>
								Pantry
							</div>
						</div>
						<ScrollSlider
							scrollProgress={scrollPercentage}
							animateScroll={jumpToNext}
							resetJumpToFlag={resetJumpToNext}
						/>
					</div>
					{/* <div className="feature-bg-text">{bgText(scrollPercentage)}</div> */}
				</div>
				<div
					className="feature-content-inner"
					style={{
						transform: `translateX(${translateX}px)`,
						transition: "all 0.3s ease-out",
					}}
					ref={objectRef}
				>
					<div className="content-container">
						<div className="feature-middle" ref={blockOne} id="block_1">
							<div className="step-instructions">
								<p>Get Help</p>
								<div className="bolder-text" style={{ marginBottom: "15px" }}>
									Stay Safer at Home!
								</div>
								<div className="step-text">
									Let PitchN volunteers pick up and Deliver food, medication,
									and supplies for free.
								</div>
								<VideoModal
									imgUrl="./img/get-help-video.png"
									videoUrl="https://www.youtube.com/embed/fevCYf9Nkyg"
								/>

								<VisibilitySensor onChange={setVideo1} partialVisibility={true}>
									<div className="video-footer">
										Check out this video!
										{showArrow1 && (
											<svg x="0px" y="0px" viewBox="0 0 43.1 85.9">
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													class="st0 draw-arrow"
													d="M11.3,2.5c-5.8,5-8.7,12.7-9,20.3s2,15.1,5.3,22c6.7,14,18,25.8,31.7,33.1"
												/>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													class="draw-arrow tail-1"
													d="M40.6,78.1C39,71.3,37.2,64.6,35.2,58"
												/>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													class="draw-arrow tail-2"
													d="M39.8,78.5c-7.2,1.7-14.3,3.3-21.5,4.9"
												/>
											</svg>
										)}
									</div>
								</VisibilitySensor>
							</div>
						</div>
						<div className="feature-right">
							<div className="feature-right-text">
								PitchN Volunteers run critical errands to keep you safe from
								Covid-19 exposure
								<div className="feature-points-alt">
									<div className="feature-point-item">
										<img alt="" src="./img/virus-icon.png" />
										<p>Reduce risk of exposure</p>
									</div>
									<div className="feature-point-item">
										<img alt="" src="./img/home-icon.png" />
										<p>Stay Home</p>
									</div>
									<div className="feature-point-item">
										<img alt="" src="./img/smiley.png" />
										<p>Stay Stress-free & Safe</p>
									</div>
								</div>
							</div>
						</div>

						<div className="feature-middle" ref={blockTwo} id="block_2">
							<div className="step-instructions">
								<p>Volunteer & Help</p>
								<div className="bolder-text" style={{ marginBottom: "15px" }}>
									Take the opportunity to help your local comunity
								</div>
								<div className="step-text">
									Help those that need it most by running errands in your local
									community.
								</div>
								<VideoModal
									imgUrl="./img/give-help-video.png"
									videoUrl="https://www.youtube.com/embed/IX3-PfcMpDM"
								/>
								<VisibilitySensor onChange={setVideo2} partialVisibility={true}>
									<div className="video-footer">
										Check out this video!
										{showArrow2 && (
											<svg
												version="1.1"
												xmlns="http://www.w3.org/2000/svg"
												x="0px"
												y="0px"
												viewBox="0 0 43.1 85.9"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													class="st0 draw-arrow"
													d="M11.3,2.5c-5.8,5-8.7,12.7-9,20.3s2,15.1,5.3,22c6.7,14,18,25.8,31.7,33.1"
												/>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													class="draw-arrow tail-1"
													d="M40.6,78.1C39,71.3,37.2,64.6,35.2,58"
												/>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													class="draw-arrow tail-2"
													d="M39.8,78.5c-7.2,1.7-14.3,3.3-21.5,4.9"
												/>
											</svg>
										)}
									</div>
								</VisibilitySensor>
							</div>
						</div>
						<div className="feature-right">
							<div className="feature-right-text">
								<div className="feature-points-title">
									Volunteer & Help
									<br /> Fight Coronavirus!
								</div>
								<div className="feature-points-description">
									Anybody 18 or over that is willing to lend a helping hand to a
									person in need.
								</div>
								<div className="feature-points-actions">
									<div
										className="feature-point-button"
										onClick={() => {
											window.location.href = "/ambassadors-faq#volunteer-form";
										}}
									>
										Sign up to volunteer
									</div>
									<div className="feature-point-button">
										Become an ambassador
									</div>
								</div>
								<div className="feature-points">
									<div className="feature-point-item">
										<img alt="" src="./img/easy-volunteer.png" />
										<p>Volunteer Easily</p>
									</div>
									<div className="feature-point-item">
										<img alt="" src="./img/supplies-pickup.png" />
										<p>Pick up food & Supplies</p>
									</div>
									<div className="feature-point-item">
										<img alt="" src="./img/home-icon.png" />
										<p>Keep the most vulnerable safe</p>
									</div>
									<div className="feature-point-item">
										<img alt="" src="./img/smiley.png" />
										<p>Make a Difference</p>
									</div>
								</div>
							</div>
						</div>

						<div className="feature-middle" ref={blockThree} id="block_3">
							<div className="step-instructions">
								<p>Share with Pantry</p>
								<div className="bolder-text" style={{ marginBottom: "15px" }}>
									A community marketplace for sharing critically needed items.
								</div>
								<div className="step-text">
									Share what you can spare and make a difference.
								</div>
								<VideoModal
									imgUrl="./img/pantry-video.png"
									videoUrl="https://www.youtube.com/embed/X1xS5p28eRY/"
								/>
								<VisibilitySensor onChange={setVideo3} partialVisibility={true}>
									<div className="video-footer">
										Check out this video!
										{showArrow3 && (
											<svg x="0px" y="0px" viewBox="0 0 43.1 85.9">
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													class="st0 draw-arrow"
													d="M11.3,2.5c-5.8,5-8.7,12.7-9,20.3s2,15.1,5.3,22c6.7,14,18,25.8,31.7,33.1"
												/>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													class="draw-arrow tail-1"
													d="M40.6,78.1C39,71.3,37.2,64.6,35.2,58"
												/>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													class="draw-arrow tail-2"
													d="M39.8,78.5c-7.2,1.7-14.3,3.3-21.5,4.9"
												/>
											</svg>
										)}
									</div>
								</VisibilitySensor>
							</div>
						</div>
						<div className="feature-right">
							<div className="feature-right-text">
								<div className="feature-points-title">
									Share what you can spare
								</div>
								<div className="feature-points-description">
									Millions need food and supplies
								</div>
								<div
									className="feature-points-description"
									style={{ marginTop: "15px" }}
								>
									Sharing is Caring
								</div>
								<div className="feature-points">
									<div className="feature-point-item">
										<img alt="" src="./img/easy-volunteer.png" />
										<p>Share Easily</p>
									</div>
									<div className="feature-point-item">
										<img alt="" src="./img/supplies-pickup.png" />
										<p>Help Those in need</p>
									</div>
									<div className="feature-point-item">
										<img alt="" src="./img/home-icon.png" />
										<p>Help your local community</p>
									</div>
									<div className="feature-point-item">
										<img alt="" src="./img/smiley.png" />
										<p>Make a Difference</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FeaturesSection;
