import React from "react";
import TagManager from "react-gtm-module";
import "./styles.scss";

class SafetyPage extends React.Component {
	componentDidMount() {
		const data = {
            event: "PageView",
			pageDetails: {
				page: "safety",
				pagePath: "/safety",
			},
		};
		TagManager.dataLayer(data);
	}

	render() {
		return (
			<div className="safety-page">
				<div className="main-title-container">
					<div className="title">Safety is a top priority</div>
					<div className="health-advisory">
						<div>
                            <img src="./img/virus.svg" alt="" />
                        </div>
						<div>
							It is important to remember that during this health crisis we all
							need to exercise extreme caution… especially when giving or receiving
							help. <br />
							<br />
							*It is always advisable to consult reputable sources such as the &nbsp;
                            <a target="_blank" rel="noopener noreferrer" 
                            href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html">
                                Center for Disease Control</a> &nbsp;
                             and The <a target="_blank" rel="noopener noreferrer" 
                             href="https://www.who.int/publications/i/item/who-2019-nCoV-surveillanceguidance-2020.7" > 
                             World Health Organization</a>&nbsp;
							regarding safety measures and precautions.
						</div>
						<div >
							*The information given was not written by a medical expert and is
							only intended to provide an interpretation of the above
							organization’s COVID-19 (SARS-CoV-2) recommendations as applied to
							the use of the PitchN app.
						</div>
					</div>
				</div>

				<div className="safety-banner">
					<div className="title">
						Safety Precautions while
						<br />
						Exchanging Goods and/or Supplies
					</div>
					<div className="description">
						<div>
							Never occupy <span className="bolder-text">the same space</span>{" "}
							at the same time!{" "}
						</div>
						<div>
							Never{" "}
							<span className="bolder-text">
								touch, shake hands, high-five, or even elbow-bump!
							</span>
						</div>
						<div>
							Remain at least <span className="bolder-text">6 feet apart</span>{" "}
							at all times during a help exchange.
						</div>
					</div>
					<div className="safety-banner-image">
						<img src="./img/heart-location.png" alt="" />
					</div>
				</div>

				<div className="best-practices">
					<div className="title">Best Practices</div>
					<div className="safety-tips">
						<div className="tip-item">
							<div>
								<img src="./img/bag.svg" alt="" />
							</div>
							<div className="tip-text">
								<span className="bolder-text">Deliver Items at doorstep</span>
								<br />
								<br />
								We recommend that the Helper leaves the goods/supplies on a Help
								Seeker’s doorstep for example or out in a hallway (or wherever
								makes sense).
							</div>
						</div>
						<div className="tip-item">
							<div>
								<img src="./img/sanitize.png" alt="" />
							</div>
							<div className="tip-text">
								<span className="bolder-text">
									Sanitize anything and everything
								</span>
								<br />
								<br />
								Receipt or items ordered, mobile phones or cash- please never
								forget to sanitize everything, maybe even twice. Be safe!
							</div>
						</div>
						<div className="tip-item">
							<div>
								<img src="./img/no-tips.png" alt="" />
							</div>
							<div className="tip-text">
								<span className="bolder-text">Free of charge</span>
								<br />
								<br />
								In the case of a good or supply provided free of charge, no
								check or cash will be exchanged.
							</div>
						</div>
						<div className="tip-item">
							<div>
								<img src="./img/distance.png" alt="" />
							</div>
							<div className="tip-text">
								<span className="bolder-text">
									NO TOUCHING. NO HIGH FIVES. NO ELBOW BUMPS
								</span>
								<br />
								<br />
								We recommend that the Helper leaves the goods/supplies on a Help
								Seeker’s doorstep for example or out in a hallway (or wherever
								makes sense).
							</div>
						</div>
					</div>
				</div>

				<div className="precaution-section">
					<div className="section-column">
						<span className="column-title">Helpers</span>
						<div>
							Do not help if you are sick
							<span className="bolder-text"> in any capacity.</span>
						</div>
						<div>
							Wash your hands
							<span className="bolder-text">
								{" "}
								with soap and water for at least 20 seconds before handling any
								goods or supplies and going on a help mission.
							</span>
						</div>
						<div>
							<span className="bolder-text">
								Wear a MASK at all times during an interaction with a volunteer.
							</span>
						</div>
						<div>
							Do not touch your face
							<span className="bolder-text"> when out on a help mission.</span>
						</div>
						<div>
							Wear protective gear
							<span className="bolder-text">
								{" "}
								if possible. Keep a distance of at least 6 feet when around an
								distance of at least 6 feet when around an individual or groups
								of people (including when you exchange goods and/or supplies
								with a Help Seeker).
							</span>
						</div>
						<div>
							Sanitize
							<span className="bolder-text">
								&nbsp;any item that you receive in the exchange of goods and/or
								supplies (cash or check).
							</span>
						</div>
						<div>
							Sanitize mobile phone
							<span className="bolder-text">
								&nbsp;used in the Help Exchange.
							</span>
						</div>
						<div>
							<span className="bolder-text">
								Remove clothes and shoes before you enter your residence and
								immediately wash with detergent and high heat
							</span>
						</div>
						<div>
							<span className="bolder-text">
								Sanitize any surfaces touched in your car or transportation
								means, and anything touched upon entering your residence.
							</span>
						</div>
					</div>
					<div className="section-column">
						<span className="column-title">Help Seekers</span>
						<div>
							<span className="bolder-text">Before </span>
							your exchange,
							<span className="bolder-text">
								{" "}
								wash your hands for at least 20 seconds with soap and water.
							</span>
						</div>
						<div>
							<span className="bolder-text">
								Wear a MASK at all times during an interaction with a volunteer.
							</span>
						</div>
						<div>
							When exchanging goods keep a distance of{" "}
							<span className="bolder-text">
								at least 6 feet from a Helper at all times.
							</span>
						</div>
						<div>
							Sanitize mobile phone{" "}
							<span className="bolder-text">
								used throughout the Help Exchange.
							</span>
						</div>
						<div>
							<span className="bolder-text">
								Wash your hands for at least 20 seconds afer handling received
								goods and/or supplies and the help exchange is concluded.
							</span>
						</div>
						<div className="disclaimer">
							* This statement has not been evaluated by the Food and Drug
							Administration, CDC, WHO, or any infectious disease experts,
							medical professionals, or any experts of any kind. These
							recommendations are not intended to diagnose, treat, cure, or
							prevent any disease.
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SafetyPage;
